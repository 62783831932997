<template>
     <TiPanel :secondary="sampleIsCreated" :tertiary="nonSuggestedJarSelected" :showCollapsedIcon="true">
        <template #title>
          <div class="property-container">
            <h4 v-for="(sampleProperty, index) in suggestSampleProperties" :key="'suggest-sample-title' + index"
              class="property-text"
              :style="{width: sampleProperty.width}">{{sampleProperty.name}}
            </h4>
          </div>
        </template>
        <template  #contentEndHeader>
          <transition name="fade">
            <button v-show="suggestedAnalysisSample.canCreate"
              class="btn btn-default create-sample-button"
              type="button"
              :disabled="disableButton"
              @click="createSample">
              <span class="glyphicon glyphicon-plus btn-icon"></span>
              {{$t('SuggestedSamples.CreateSample')}}
            </button>
          </transition>
          <transition name="fade">
            <div v-if="sampleIsCreated" class="created-sample-name-container">
            <span class="glyphicon glyphicon-ok"></span>
            <h4>{{suggestedAnalysisSample.analysisSampleName}}</h4>
          </div>
          </transition>
        </template>
        <template #content>
          <div class="container">
            <div class="row">
              <div class="col-sm-12" >
                <div class="col-sm-2">
                  <div v-if="measurementPointsAvailable">
                    <SuggestAnalysisSampleMap
                      :key="mapKey"
                      :sampleNumber="suggestedAnalysisSample.number"
                      :sampleMeasurementPointMarkers="sampleMeasurementPointFeatures"
                      :otherMeasurementPointMarkers="otherMeasurementPointFeatures" />
                  </div>
                  <div v-else class="no-markers-container">
                    <h5> No coordinates </h5>
                  </div>
                </div>
                <div class="col-sm-10 sample-jars-container" >
                  <SuggestAnalysisSampleJarCard
                    v-for="(jar, jarIndex) in suggestedAnalysisSample.jars"
                    :key="suggestedAnalysisSample.number + '_JarCard_' + jarIndex"
                    :sampleJar="jar"
                    :sampleNumber="suggestedAnalysisSample.number"
                    :excludeCheckboxIsReadonly="!suggestedAnalysisSample.canCreate"
                    :sampleJarIndex="jarIndex"
                    class="sample-jar-card"
                    @excludedJarChanged="excludedJarChanged"
                    @switchJar="switchJar"
                  />
                </div>
              </div>
            </div>
            <div class="all-measurement-object-container">
              <div v-if="showOtherMeasurementPoints">
                <div v-if="unusedMeasurementPoints.length < 1" class="no-result-container">
                  <p>{{$t('SuggestedSamples.NoOtherMeasurementPoints ')}}</p>
                </div>
                <div v-else>
                  <div class="other-measurement-header-container">
                    <p>{{$t('SuggestedSamples.OtherMeasurementPoints')}}:</p>
                    <div class="with-jars-checkbox">
                      <input id="showJars" v-model="showJarsInOtherMeasurementPoints" class="with-jars-checkbox-input" type="checkbox" name="showJars" />
                      <label class="with-jars-checkbox-label" for="showJars">{{$t('SuggestedSamples.ShowJars')}}</label>
                    </div>
                  </div>
                  <div class="measurement-object-cards-container">
                    <SuggestAnalysisSampleJarCard
                      v-for="(jar, jarIndex) in unusedMeasurementPoints"
                      :key="suggestedAnalysisSample.number + '_JarCard_' + jarIndex"
                      :sampleJar="jar"
                      :sampleNumber="suggestedAnalysisSample.number"
                      :sampleJarIndex="jarIndex"
                      :showReasons="false"
                      :showScore="false"
                      :showCheckbox="false"
                      :showJars="showJarsInOtherMeasurementPoints"
                      :excludeCheckboxIsReadonly="false"
                      class="sample-jar-card"
                    />
                  </div>
                </div>
              </div>
              <div class="collapsing-container" @click="setShowOtherMeasurementPoints">
                <p class="collapsing-container-text">{{$t('SuggestedSamples.OtherMeasurementPoints')}}</p>
                <font-awesome-icon
                    class="collapsing-container-icon"
                    :icon="showOtherMeasurementPoints ? 'fa-solid fa-chevron-up' : 'fa-solid fa-chevron-down'" />
              </div>
            </div>
          </div>
        </template>
    </TiPanel>
</template>

<script>
import TiPanel from '@/components/panels/TiPanel'
import SuggestAnalysisSampleJarCard from '@/components/suggest-analysis-sample/SuggestAnalysisSampleJarCard'
import SuggestAnalysisSampleMap from '@/components/suggest-analysis-sample/SuggestAnalysisSampleMap'
import Vue from 'vue'
export default {
  name: 'SuggestAnalysisSampleCard',
  components: {
    SuggestAnalysisSampleMap,
    SuggestAnalysisSampleJarCard,
    TiPanel
  },
  props: {
    measurementPointFeatures: {
      type: Array,
      required: true
    },
    suggestedAnalysisSample: {
      type: Object,
      required: true
    },
    allMeasurementPoints: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      lang: Vue.config.lang,
      sampleMeasurementPointFeatures: [],
      otherMeasurementPointFeatures: [],
      mapKey: this.generateMapKey(),
      excludedJarIds: [],
      showOtherMeasurementPoints: false,
      showJarsInOtherMeasurementPoints: false
    }
  },
  mounted () {
    if (this.suggestedAnalysisSample && this.suggestedAnalysisSample.jars) {
      this.seperateMeasurementPoints()
    }
  },
  methods: {
    seperateMeasurementPoints () {
      const mpGuidArray = this.suggestedAnalysisSample.jars.map(
        (jar) => jar.measurementPoint.guid
      )
      this.sampleMeasurementPointFeatures = []
      this.otherMeasurementPointFeatures = []
      for (let i = 0; i < this.measurementPointFeatures.length; i++) {
        if (mpGuidArray.includes(this.measurementPointFeatures[i].Guid)) {
          this.sampleMeasurementPointFeatures.push(this.measurementPointFeatures[i])
        } else {
          this.otherMeasurementPointFeatures.push(this.measurementPointFeatures[i])
        }
      }
    },
    setShowOtherMeasurementPoints() {
      this.showOtherMeasurementPoints = !this.showOtherMeasurementPoints
    },
    createSample(event) {
      event.stopPropagation()
      this.$emit('createSample', { number: this.suggestedAnalysisSample.number, jars: this.selectedJars })
    },
    generateMapKey() {
      // uses Math random to ensure new key is generated when sample data changes
      this.mapKey = `${this.suggestedAnalysisSample.number}suggested_map${Math.floor(Math.random() * 100)}`
    },
    excludedJarChanged(mpGuid) {
      const jarId = this.suggestedJars.find(jar => jar.mpGuid === mpGuid).guid
      const index = this.excludedJarIds.indexOf(jarId)
      if (index === -1) {
        this.excludedJarIds.push(jarId)
        return
      }
      this.excludedJarIds.splice(index, 1)
    },
    switchJar(sample) {
      this.$emit('switchJar',
        {
          sampleNumber: this.suggestedAnalysisSample.number,
          sampleJarIndex: sample.sampleJarIndex,
          layerIndex: sample.layerIndex,
          jarIndex: sample.jarIndex
        })
    }
  },
  watch: {
    suggestedAnalysisSample: {
      handler() {
        this.seperateMeasurementPoints()
        // force re-render the map
        this.generateMapKey()
      }
    }
  },
  computed: {
    measurementPointsAvailable() {
      return this.sampleMeasurementPointFeatures.length > 0
    },
    suggestSampleProperties() {
      // list of properties to go in the header put in an object with width for perfect alignment
      return [
        { name: `${this.$t('SuggestedSamples.SampleNumber')}: ${this.suggestedAnalysisSample.number}`, width: '170px' },
        { name: `${this.$t('SuggestedSamples.Score')}: ${this.suggestedAnalysisSample.score}`, width: '100px' },
        { name: `${this.$t('SuggestedSamples.Jars')}: ${this.selectedJars.length}`, width: this.lang === 'nl' ? '80px' : '60px' },
        { name: `${this.$t('SuggestedSamples.SoilType')}: ${this.suggestedAnalysisSample.profile.soilType.description}`, width: this.lang === 'nl' ? '165px' : '150px' },
        { name: `${this.$t('SuggestedSamples.SoilDepth')}: ${this.suggestedAnalysisSample.profile.soilDepthType.description}`, width: this.lang === 'nl' ? '185px' : '150px' },
        { name: `${this.$t('SuggestedSamples.SubLocation')}: ${this.suggestedAnalysisSample.profile.subLocationName}`, width: '250px' }
      ]
    },
    suggestedJars() {
      return this.suggestedAnalysisSample.jars.reduce((suggestedJars, jar) => {
        const selectedJar = jar
          .measurementPoint
          .layers.find(layer => layer.selectedByUser)
          .jars.find(jar => jar.selectedByUser)
        suggestedJars.push({ ...selectedJar, mpName: jar.measurementPoint.name, mpGuid: jar.measurementPoint.guid })
        return suggestedJars
      }, [])
    },
    unusedMeasurementPoints() {
      const usedMeasurementPointsGuid = this.suggestedAnalysisSample.jars.map(jar => jar.measurementPoint.guid)
      const sublocationId = this.suggestedAnalysisSample.profile.sublocationId
      if (!sublocationId) {
        return this.allMeasurementPoints.filter(measurementPoint => !usedMeasurementPointsGuid.includes(measurementPoint.measurementPoint.guid))
      }

      return this.allMeasurementPoints.filter(measurementPoint => !usedMeasurementPointsGuid.includes(measurementPoint.measurementPoint.guid) &&
        measurementPoint.measurementPoint.sublocationId === sublocationId)
    },
    disableButton() {
      return this.excludedJarIds.length === this.suggestedJars.length
    },
    selectedJars() {
      if (this.excludedJarIds.length === 0) {
        return this.suggestedJars
      }
      return this.suggestedJars.reduce((selectedJars, suggestedJar) => {
        if (!this.excludedJarIds.includes(suggestedJar.guid)) {
          selectedJars.push(suggestedJar)
        }
        return selectedJars
      }, [])
    },
    sampleIsCreated() {
      return this.suggestedAnalysisSample.analysisSampleName !== ''
    },
    nonSuggestedJarSelected() {
      return this.sampleIsCreated === false &&
      (
        this.excludedJarIds.length > 0 ||
        this.selectedJars.findIndex(selectedJar => selectedJar.hasBeenSuggested === false) > -1
      )
    }
  }
}
</script>
<style scoped>
.form-control-static{
  text-align: left !important;
  padding: 7px 0px 0px 0px;
}

.dx-toolbar-item h2 {
  margin-top: 5px;
  margin-left: 10px;
  font-size: 25px;
}
.dx-datagrid-search-panel {
  margin: 0 0 0 0;
}
.dx-toolbar-after {
  margin-top: 5px;
  margin-right: 5px;
}
.markers-column {
  height: 300px;
}
.no-markers-container {
  height:  100%;
  width: 100%;
  background-color: #aba5a5;
  margin: 10px 0 0 auto;
  padding: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
}
.no-markers-container h5 {
  text-align: center;
  color: white;
}
.container {
  width: 100%;
  background-color:white;
}
.property-container {
  display: flex;
}
.property-text {
  white-space: nowrap;
  overflow: hidden;
  margin-right: 10px;
  text-overflow: ellipsis;
}
.sample-jars-container {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
}
.sample-jar-card {
  margin: 10px;
}
.create-sample-button {
  height: 34px;
  display: flex;
  border: 1px solid #dbd9d7;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 50px;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  margin: 0;
  transition: all .2s cubic-bezier(.36,.03,.28,.99);
  color: #3b312a;
  text-decoration: none;
  box-shadow: 0 4px 10px rgb(0 0 0 / 7%);
  position: relative;
  white-space: nowrap;
  outline: none;
}
.create-sample-button:hover {
  background-color: #f2f7ee;
  color: #3b312a;
  outline: none;
}
.btn-icon {
  color: #67ac45;
  flex-shrink: 1;
  margin-right: 4px;
}
.created-sample-name-container {
  display: flex;
  align-items: baseline;
  padding-right: 15px;
}
.created-sample-name-container span {
  margin-right: 10px;
}
.fade-enter-active, .fade-leave-active {
    transition: opacity .8s;
  }
.fade-enter, .fade-leave-to{
  opacity: 0;
}
.all-measurement-object-container {
  background-color: #D2D6DB;
  margin-top: 8px;
  border-radius: 4px;
  font-size: 18px;
  color: #384150;
}
.other-measurement-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 16px 0 16px;
}
.with-jars-checkbox {
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: center;
  align-items: center;
}
.with-jars-checkbox-input {
  margin: 0px;
  transform: scale(1.5);
}
.with-jars-checkbox-label {
  font-size: 18px;
  font-weight: 500;
}
.no-result-container {
  display: flex;
  justify-content: center;
  padding: 16px 0px 8px 0px;
  font-size: 18px;
}
.measurement-object-cards-container {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
}
.collapsing-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 0px;
  gap: 44px;
}
.collapsing-container-icon {
  height: 18px;
  color: #4D5660;
}
.collapsing-container-text {
  margin: 0px;
}
</style>
