<template>
  <TiPanel :title="$t('SuggestedSamples.OutputSummary')" :collapsible="false" secondary>
    <template #content>
      <form
        id="suggested-analysis-sample-output"
        class="form-horizontal row"
      >
        <div class="col-sm-offset-1 col-sm-10 output-summary-container">
          <div class="form-group row">
            <label for="totalSamples" class="control-label col-sm-9">
              {{$t('SuggestedSamples.TotalSuggestedSamples')}}
            </label>
            <div class="col-sm-3 total-count-value">
              <p> {{ totalSuggestSampleCount }} </p>
            </div>
          </div>
          <div class="form-group row">
            <div class="control-label col-sm-offset-4 col-sm-5 output-value-label">
              {{$t('SuggestedSamples.Topsoil')}}
            </div>
            <div class="col-sm-3 output-value-text">
              <p> {{topSoilCount}} </p>
            </div>
          </div>
          <div class="form-group row">
            <div class="control-label col-sm-offset-4 col-sm-5 output-value-label">
              {{$t('SuggestedSamples.Midsoil')}}
            </div>
            <div class="col-sm-3 output-value-text">
              <p> {{midSoilCount}} </p>
            </div>
          </div>
          <div class="form-group row">
            <div class="control-label col-sm-offset-4 col-sm-5 output-value-label">
              {{$t('SuggestedSamples.Lowersoil')}}
            </div>
            <div class="col-sm-3 output-value-text">
              <p> {{lowerSoilCount}} </p>
            </div>
          </div>
        </div>
        <div class="col-sm-offset-1 col-sm-10 output-sublocation-container">
          <div class="form-group row">
            <label for="totalSamples" class="control-label col-sm-7 sublocation-title">
              {{$t('SuggestedSamples.SuggestedSamplesPerSubLocation')}}
            </label>
          </div>
          <div class="sublocationScrollableContainer">
            <div v-for="(subLocation, index) in subLocationGroupedByNameList" :key="'subLocation_item_' + index" class="form-group row">
              <div class="control-label col-sm-offset-4 col-sm-5 output-value-label">
                {{subLocation.subLocationName}}
              </div>
              <div class="col-sm-3 output-value-text">
                <p> {{subLocation.count}} </p>
              </div>
            </div>
          </div>
        </div>
        <div class="control-label col-sm-offset-1 col-sm-10 error-container">
          <div class="form-group row">
            <label for="error-list" class="control-label col-sm-6">
              {{$t('SuggestedSamples.Errors')}}
            </label>
          </div>
          <div class="row error-messages-scrollable-container">
            <div v-for="(logErrorMessage, index) in errorList" :key="'error_item_' + index" class="form-group row">
              <div class="col-sm-12 error-log-text">
                {{logErrorMessage}}
              </div>
            </div>
          </div>
        </div>
        <div class="control-label col-sm-offset-1 col-sm-11 output-dumps-container">
          <div v-if="userHasDeveloperLicense" class="form-group row">
            <label for="dump-label" class="control-label col-sm-6">
              <label id="dump-label">Dumps</label>
            </label>
          </div>
          <div class="row col-sm-12 dump-btn-group">
            <a
              v-for="dump in logDumpsWithDevelopLicense"
              :key="'dump_' + dump.title"
              class="btn btn-submit dump-btn"
              :title="dump.title"
              :download="dump.download"
              :href="dump.href"
              >
              {{dump.title}}
            </a>
          </div>
        </div>
      </form>
    </template>
  </TiPanel>
</template>

<script>
import TiPanel from '@/components/panels/TiPanel'

export default {
  name: 'SuggestAnalysisSamplesOutputContainer',
  components: {
    TiPanel
  },
  props: {
    suggestedAnalysisSamplesResults: {
      type: Array,
      required: true
    },
    codeLists: {
      type: Object,
      required: true
    },
    veldData: {
      type: Object,
      required: true
    },
    projectCode: {
      type: String,
      required: true
    },
    userHasDeveloperLicense: {
      type: Boolean,
      required: true
    },
    resultLog: {
      type: Array,
      required: true
    }
  },
  computed: {
    topSoilCount() {
      if (!this.suggestedAnalysisSamplesResults) {
        return 0
      }
      return this.suggestedAnalysisSamplesResults.reduce((total, s) => total + (s.profile.soilDepth === 'TopSoil'), 0)
    },
    midSoilCount() {
      if (!this.suggestedAnalysisSamplesResults) {
        return 0
      }
      return this.suggestedAnalysisSamplesResults.reduce((total, s) => total + (s.profile.soilDepth === 'MidSoil'), 0)
    },
    lowerSoilCount() {
      if (!this.suggestedAnalysisSamplesResults) {
        return 0
      }
      return this.suggestedAnalysisSamplesResults.reduce((total, s) => total + (s.profile.soilDepth === 'LowerSoil'), 0)
    },
    subLocationGroupedByNameList() {
      if (!Array.isArray(this.suggestedAnalysisSamplesResults)) {
        return []
      }
      return this.getSubLocationNamesWithCount(this.suggestedAnalysisSamplesResults)
    },
    errorList() {
      if (this.resultLog.length > 0) {
        const errors = this.resultLog.reduce(this.formatErrorLogReduce, [])
        if (errors.length > 0) {
          return errors
        }
      }
      if (this.suggestedAnalysisSamplesResults.length <= 0) {
        return [this.$t('SuggestedSamples.NoSamplesSuggested')]
      }

      return [this.$t('SuggestedSamples.None')]
    },
    logDumpsWithDevelopLicense() {
      const dumps = []
      if (this.resultLog.length > 0) {
        dumps.push({ title: this.$t('SuggestedSamples.DownloadLogFile'), download: `${this.$t('SuggestedSamples.SampleLogFileName')}.txt`, href: this.logFileHref() })
      }
      if (!this.userHasDeveloperLicense) {
        return dumps
      }

      dumps.push({ title: this.$t('SuggestedSamples.Project'), download: this.downloadProjectFileName, href: this.projectFileHref() })
      dumps.push({ title: this.$t('SuggestedSamples.Codelist'), download: `${this.$t('SuggestedSamples.SampleCodeListFileName')}.txt`, href: this.codeListFileHref() })

      if (this.suggestedAnalysisSamplesResults.length > 0) {
        dumps.push({ title: this.$t('SuggestedSamples.SuggestSampleResults'), download: this.suggestSampleResultsFileName, href: this.sampleResultFileHref() })
      }
      return dumps
    },
    downloadProjectFileName() {
      return this.projectCode ? `${this.$t('SuggestedSamples.Project').toLowerCase()}_${this.projectCode}.txt` : ''
    },
    suggestSampleResultsFileName() {
      return this.projectCode ? `${this.$t('SuggestedSamples.SampleResultsFileName').toLowerCase()}_${this.projectCode}.txt` : ''
    },
    totalSuggestSampleCount() {
      return this.suggestedAnalysisSamplesResults.length
    }
  },
  methods: {
    getSubLocationNamesWithCount(sampleResults) {
      let unnamedSubLocationsCount = 0
      const subLocations = []
      for (let i = 0; i < sampleResults.length; i++) {
        if (sampleResults[i].profile.subLocationName) {
          const subLocation = subLocations.find(subLocation => subLocation.subLocationName === sampleResults[i].profile.subLocationName)
          if (subLocation) {
            subLocation.count++
          } else {
            subLocations.push({ subLocationName: sampleResults[i].profile.subLocationName, count: 1 })
          }
        } else {
          unnamedSubLocationsCount++
        }
      }
      const sortedSubLocations = subLocations.sort((a, b) => (a.subLocationName > b.subLocationName) ? 1 : ((b.subLocationName > a.subLocationName) ? -1 : 0))
      if (unnamedSubLocationsCount > 0) {
        sortedSubLocations.push({ subLocationName: this.$t('SuggestedSamples.NoSubLocation'), count: unnamedSubLocationsCount })
      }

      return sortedSubLocations
    },

    formatErrorLogReduce(errorList, record) {
      if (record.type === 'Error') {
        errorList.push(record.message)
      }
      return errorList
    },
    logFileHref() {
      return URL.createObjectURL(new Blob(this.resultLog.map(r => JSON.stringify(r, null, 4) + '\n'), { type: 'text/plain' }))
    },
    projectFileHref() {
      return URL.createObjectURL(new Blob(Object.entries(this.veldData).map(r => JSON.stringify(r, null, 4) + '\n'), { type: 'text/plain' }))
    },
    codeListFileHref() {
      return URL.createObjectURL(new Blob(Object.entries(this.codeLists).map(c => JSON.stringify(c, null, 4) + '\n'), { type: 'text/plain' }))
    },
    sampleResultFileHref() {
      return URL.createObjectURL(new Blob(this.suggestedAnalysisSamplesResults.map(r => JSON.stringify(r, null, 4) + '\n'), { type: 'text/plain' }))
    }
  }
}
</script>
<style scoped >
.sublocationScrollableContainer {
  max-height: 120px;
  margin-top: 4px;
  overflow-y: auto;
  overflow-x: hidden;
}
.error-container {
  border-top-style: double;
  padding: 0;
}
label {
  white-space: normal;
}
.error-messages-scrollable-container {
  max-height: 85px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 20px;
  margin-right: 10px;
  background-color: #eaeaea08;
  border-radius: 4px;
  margin-top: 14px;
}
.pin-to-bottom-left-corner-container {
  position: absolute;
  bottom: 0;
  margin: 0;
  left: 0;
  width: 100%;
  overflow-x: auto;
}
.error-log-text {
  white-space: normal;
  margin: 0px 0px 10px -10px;
}
.dumps-button-container {
  display: inline-flex;
}
.dumps-button-container a {
  text-overflow: ellipsis;
  overflow-x: hidden;
  text-align: center;
  max-height: 35px;
  font-size: small;
  max-width: 160px;
  margin-right: 8px;
}
.output-dumps-container {
  position: absolute;
  bottom: 24px;
}
.output-summary-container {
  padding-left: 0;
  height: 125px;
}
.output-sublocation-container {
  padding-left: 0;
  min-height: 65px;
  max-height: 150px;
}
.sublocation-title {
  width: 100%;
}
.total-count-value {
  padding-left: 0;
}
.output-value-label {
  padding-left: 0px !important;
  padding-top: 2px !important;
  white-space: normal;
}
.output-value-text {
  padding-left: 0px;
}
.output-value-text p {
  padding-top: 2px;
}
.dump-btn {
  margin: 3px 5px 0 5px;
}
.dump-btn-group {
  padding-right: 0;
}
</style>
