<template>
     <div class="depth-input-container">
        <div class="row borelog-layer-row">
            <div class="col-sm-6 borelog-layer-container">
                <div class="borelog-dash-col">
                    <div class="borelog-dash-text borelog-zero-text">
                        0
                    </div>
                    <div class="borelog-dash-text">
                        -
                    </div>
                </div>
                <div class="borelog-layer surface-level-color"/>
            </div>
        </div>
        <div class="row borelog-layer-row">
            <div class="col-sm-6 borelog-layer-container">
                <div class="borelog-dash-col">
                    <div v-for="index in 9"
                        :key="'topsoil_lines' + index"
                        class="borelog-dash-text"
                        :style="{top: dashPositionTopSoil(index)}" >
                        -
                    </div>
                </div>
                <div class="borelog-layer top-level-color" />
                <div class="borelog-side-text-container">
                    <div class="soil-type-dropdown">
                        <MinimumSoilDropdown
                            :label="$t('SuggestedSamples.Topsoil')"
                            :value="topSoilMinimumValue"
                          @update:value="topSoilMinimumChanged"
                        />
                    </div>
                    <div for="upperSoilDepth" class="soil-input-label">
                      {{$t('SuggestedSamples.UpperSoilDepth')}}
                    </div>
                </div>
            </div>
        </div>
        <div class="row borelog-line-row">
            <div class="col-sm-6 borelog-line">
            </div>
            <div class="col-sm-5 soil-depth-input-container">
                <div class="input-group soil-depth-input">
                <input
                    id="upper-soil-depth"
                    v-model="upperSoilDepthValue"
                    type="number"
                    class="form-control"
                    min="0"
                    max="2147483647"
                />
                <div class="input-group-addon">cm</div>
                </div>
            </div>
        </div>
        <div class="row borelog-layer-row">
            <div class="col-sm-6 borelog-layer-container">
                <div class="borelog-dash-col">
                    <div v-for="index in 9"
                        :key="'topsoil_lines' + index"
                        class="borelog-dash-text"
                        :style="{top: dashPositionMidSoil(index) }" >
                        -
                    </div>
                </div>
                <div class="borelog-layer mid-level-color" />
                <div class="borelog-side-text-container">
                    <div class="soil-type-dropdown">
                        <MinimumSoilDropdown
                            :label="$t('SuggestedSamples.Midsoil')"
                            :value="midSoilMinimumValue"
                            @update:value="midSoilMinimumChanged"
                        />
                    </div>
                    <div for="lowerSoilDepth" class="soil-input-label">
                      {{$t('SuggestedSamples.LowerSoilDepth')}}
                    </div>
                </div>
            </div>
        </div>
        <div class="row borelog-line-row">
            <div class="col-sm-6 borelog-line">
            </div>
            <div class="col-sm-5 soil-depth-input-container">
                <div class="input-group soil-depth-input">
                <input
                    id="lower-soil-depth"
                    v-model="lowerSoilDepthValue"
                    type="number"
                    class="form-control"
                    :class="
                    lowerDepthValid
                      ? ''
                      : 'invalid'
                        "
                    min="0"
                    max="2147483647"
                />
                <div class="input-group-addon">cm</div>
                </div>
            </div>
        </div>
        <div class="row lower-layer-row">
            <div class="col-sm-6 borelog-layer-container">
                <div class="borelog-dash-col">
                    <div v-for="index in 7"
                        :key="'topsoil_lines' + index"
                        class="borelog-dash-text"
                        :style="{top: dashPositionLowerSoil(index) }" >
                        -
                    </div>
                </div>
                <div class="borelog-layer lower-level-color" />
                <div class="borelog-side-text-container">
                    <div class="soil-type-dropdown lower-soil-dropdown">
                        <MinimumSoilDropdown
                            :label="$t('SuggestedSamples.Lowersoil')"
                            :value="lowerSoilMinimumValue"
                            @update:value="lowerSoilMinimumChanged"
                        />
                        <p class="max-depth-text">{{$t('SuggestedSamples.MaxDepth')}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
                <div class="col-sm-6 max-depth-line-container">
                    <div class="max-depth-line"/>
                </div>
                <div class="col-sm-5 max-depth-input-container">
                    <div class="input-group soil-depth-input">
                    <p v-if="showMaxDepthOptionText" class="optional-text">{{$t('SuggestedSamples.Optional')}}</p>
                    <input
                        id="max-soil-depth"
                        v-model="maxSoilDepthValue"
                        type="number"
                        class="form-control"
                        :class="
                        maxDepthValid
                        ? ''
                        : 'invalid'"
                        min="0"
                        max="2147483647"
                        @change="hideOptionText"
                    />
                    <div class="input-group-addon">cm</div>
                    </div>
                </div>
        </div>
     </div>
</template>

<script>
import MinimumSoilDropdown from './MinimumSoilDropdown.vue'
export default {
  name: 'SuggestAnalysisSampleDepthInputs',
  components: {
    MinimumSoilDropdown
  },
  props: {
    maxDepthValid: {
      type: Boolean,
      required: true
    },
    lowerDepthValid: {
      type: Boolean,
      required: true
    },
    upperSoilDepth: {
      type: Number,
      required: true
    },
    lowerSoilDepth: {
      type: Number,
      required: true
    },
    maxSoilDepth: {
      type: Number,
      required: true
    },
    topSoilMinimum: {
      type: Number,
      required: true
    },
    midSoilMinimum: {
      type: Number,
      required: true
    },
    lowerSoilMinimum: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      showMaxDepthOptionText: true
    }
  },
  methods: {
    dashPositionTopSoil(index) {
      return (((index - 1) * 10) - 10) + 'px'
    },
    dashPositionMidSoil(index) {
      return ((index * 10) - 10) + 'px'
    },
    dashPositionLowerSoil(index) {
      return (index * 10) + 'px'
    },
    topSoilMinimumChanged(topSoilMinimum) {
      this.topSoilMinimumValue = topSoilMinimum
    },
    midSoilMinimumChanged(midSoilMinimum) {
      this.midSoilMinimumValue = midSoilMinimum
    },
    lowerSoilMinimumChanged(lowerSoilMinimum) {
      this.lowerSoilMinimumValue = lowerSoilMinimum
    },
    hideOptionText(e) {
      // need this because if text is 0 vue does not register it as a change if it goes from empty string to 0 we don't want optional text showing
      this.showMaxDepthOptionText = e.target.value === ''
    }
  },
  computed: {
    upperSoilDepthValue: {
      get() {
        return this.upperSoilDepth
      },
      set(value) {
        this.$emit('update:upperSoilDepth', Number(value))
      }
    },
    lowerSoilDepthValue: {
      get() {
        return this.lowerSoilDepth
      },
      set(value) {
        this.$emit('update:lowerSoilDepth', Number(value))
      }
    },
    maxSoilDepthValue: {
      get() {
        return this.maxSoilDepth === 0 ? '' : this.maxSoilDepth
      },
      set(value) {
        this.$emit('update:maxSoilDepth', Number(value))
      }
    },
    topSoilMinimumValue: {
      get() {
        return this.topSoilMinimum
      },
      set(value) {
        this.$emit('update:topSoilMinimum', Number(value))
      }
    },
    midSoilMinimumValue: {
      get() {
        return this.midSoilMinimum
      },
      set(value) {
        this.$emit('update:midSoilMinimum', Number(value))
      }
    },
    lowerSoilMinimumValue: {
      get() {
        return this.lowerSoilMinimum
      },
      set(value) {
        this.$emit('update:lowerSoilMinimum', Number(value))
      }
    }
  }
}
</script>
<style scoped>
.depth-input-container {
  min-width: 450px;
}

.borelog-layer-container {
  height: 100%;
  display: flex;
  margin:0px;
  padding: 0px;
}

.lower-layer-row {
  height: 85px;
}

.borelog-layer-row {
  height: 80px;
}

.borelog-layer-row:first-child {
  height: 10px;
}

.borelog-line-row {
  height: 3px;
}

.borelog-dash-col {
  position: relative;
  width: 8px;
  flex-shrink: 0;
}

.borelog-dash-text {
  position: absolute;
  top: -10px;
}

.borelog-zero-text {
  left: -10px;
}

.borelog-layer {
  height: 100%;
  width: 70px;
  flex-shrink: 0;
}

.borelog-line {
  background-color: black;
  height: 100%;
  margin-left: 8px;
  display: flex;
  padding: 0px;
  width: 52%;
}

.soil-type-dropdown {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 2;
  padding: 5px 5px 0 2px;
}

.soil-input-label {
  white-space: nowrap;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex: 1;
  padding-right: 6px;
}

.borelog-side-text-container {
  flex: 1;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

.soil-depth-input-container {
  position: relative
}

.soil-depth-input {
  position: absolute;
  width: 92px;
}

.soil-depth-input input {
  padding: 6px 5px;
  text-align: center;
}

.soil-depth-input .input-group-addon {
  padding: 6px 8px;
}

.soil-depth-input-container .soil-depth-input {
  top: -18px;
}

.invalid {
  border-color: #dc3545;
}

.surface-level-color {
  background-Color: green;
}

.top-level-color {
  background-Color: #C26000;
}

.mid-level-color {
  background-Color: #994C00;
}

.lower-level-color {
  background-Color: #6B3500;
}

.max-depth-line-container {
  position: relative;
  width: 52%;
}

.max-depth-input-container {
  position: relative;
}

.max-depth-input-container .soil-depth-input {
  bottom: 0px;
  left: 24px;
}

.max-depth-input-container .soil-depth-input input {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.max-depth-line {
  height: 4px;
  position: absolute;
  bottom: 14px;
  width: 100%;
  left: 8px;
  border-top: solid;
  border-width: 3.4px;
  text-align: end;
}

.max-depth-text {
  width: 100%;
  text-align: end;
  padding-top: 3px;
}

.max-depth-input input {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.optional-text {
  position: absolute;
  top: 30%;
  left: 10%;
  z-index: 3;
  padding: 0;
  font-size: 10px;
  color: lightgrey;
  pointer-events: none;
  cursor: default;
}

.lower-soil-dropdown {
  flex-direction: column;
}
</style>
