<template>
    <div class="dropdown-container">
        <select v-model="selectedValue" class="select">
            <option class="item" :value="1">{{$t('SuggestedSamples.MinimumOfOneSuggestedSamples')}}</option>
            <option class="item" :value="0">{{$t('SuggestedSamples.MinimumOfZeroSuggestedSamples')}}</option>
            <option class="item" :value="-1">{{$t('SuggestedSamples.NoSuggestSamples')}}</option>
        </select>
        <label class="label">{{label}}</label>
    </div>
  </template>

<script>

export default {
  name: 'MinimumSoilDropdown',
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: Number,
      required: true
    }
  },
  methods: {

  },
  computed: {
    selectedValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('update:value', Number(value))
      }
    }
  }
}
</script>

<style scoped >
.dropdown-container {
    position: relative;
    width: 180px;
}
.label {
    position: absolute;
    z-index: 2;
    padding-left: 5px;
    left: 0;
    top: 1px;
    color: black;
    pointer-events: none;
    font-weight: 600;
    font-size: 12px;
}
.select {
    position: relative;
    display: inline-block;
    width: 100%;
    border-radius: 10px;
    padding: 4px 0 0 5px;
    height: 40px;
    line-height: 42px;
    border: 1px solid #dbd9d7;
    background-color: white;
    margin: 0;
    color: #7d7a77;
    font-weight: 400;
    font-size: 11.5px;
    z-index: 1;
    text-align: left;
    -webkit-appearance: none;
    background-repeat: no-repeat;
    background-position: top 8px right 3px;
    background-size: 10px 8px;
    word-wrap: normal;
    background-image: url('../../assets/svg/icon-dropdown.svg');
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}
.select:focus {
    border: 1px solid #898887 !important;
    outline: none;
}

.select option {
    cursor: pointer;
    color: #898887;
    font-size: 16px;
}
.select option:hover {
    color: #444645;
    box-shadow: 0 0 10px 100px rgba(225, 225, 225, 0.5) inset;
}

.select:focus>option:checked {
  background: #67ac45;
  color: white;
}

</style>
