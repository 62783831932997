<template>
    <div :id="'Fullscreen' + sampleNumber" class="full-screen-suggested-sample-card-map">
        <div  :id="'SampleMap'+ sampleNumber"  class="analysis-sample-map"></div>
    </div>
</template>

<script>
/* eslint new-cap: ["error", { "newIsCap": false }] */
import { defaults as defaultControls, FullScreen } from 'ol/control'
import { boundingExtent, buffer } from 'ol/extent'
import olFeature from 'ol/Feature'
import olGeomPoint from 'ol/geom/Point'
import TileLayer from 'ol/layer/Tile'
import olLayerVector from 'ol/layer/Vector'
import olMap from 'ol/Map'
import { fromLonLat } from 'ol/proj'
import OSM from 'ol/source/OSM'
import olSourceVector from 'ol/source/Vector'
import olStyleFill from 'ol/style/Fill'
import olStyleIcon from 'ol/style/Icon'
import olStyleStroke from 'ol/style/Stroke'
import olStyleStyle from 'ol/style/Style'
import olStyleText from 'ol/style/Text'
import olView from 'ol/View'

export default {
  name: 'SuggestAnalysisSampleMap',
  props: {
    sampleNumber: {
      type: Number,
      required: true
    },
    sampleMeasurementPointMarkers: {
      type: Array,
      required: true
    },
    otherMeasurementPointMarkers: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      map: null,
      bufferBoundaryMeters: 120,
      zoomLevel: 13,
      localMarkerColor: '#d91111',
      otherMarkerColor: '#67ac45',
      defaultStyleSVG:
        'data:image/svg+xml;utf8,' +
        encodeURIComponent(
          '<svg xmlns="http://www.w3.org/2000/svg" width="100px" height="100px"><rect x="48" y="24.8" fill="#67ac45" width="4" height="50.3"/><path fill="#67ac45" d="M50,33.8c8.8,0,16,7.2,16,16s-7.2,16-16,16s-16-7.2-16-16S41.2,33.8,50,33.8 M50,29.8c-11,0-20,9-20,20 s9,20,20,20s20-9,20-20S61,29.8,50,29.8L50,29.8z"/><rect x="24.8" y="47.9" fill="#67ac45" width="50.3" height="4"/></svg>'
        ),
      gaugingTubeStyleSVG:
        'data:image/svg+xml;utf8,' +
        encodeURIComponent(
          '<svg xmlns="http://www.w3.org/2000/svg" width="100px" height="100px"> <path fill="#67ac45" d="M79.8,31.7c-0.2-0.7-0.7-1.4-1.4-2.1l-4.8-4.8L63.3,35.1l-2.8,2.8c1,0.9,1.8,1.8,2.6,2.9l5.2-5.2l1.5,1.4 c0.6,0.7,1.3,1.2,2,1.5c0.7,0.4,1.4,0.6,2.1,0.6c0.7,0.1,1.4-0.1,2.1-0.4c0.7-0.3,1.4-0.8,2.1-1.5c0.5-0.5,0.9-1.1,1.3-1.7 c0.4-0.6,0.6-1.3,0.7-1.9C80.1,33.1,80,32.4,79.8,31.7z M75.3,34.6c-0.7,0.7-1.4,1-2,1c-0.7,0-1.3-0.3-1.8-0.8l-1.2-1.2l4.1-4.1 l1.1,1.1c0.7,0.7,1,1.3,0.9,2C76.3,33.3,75.9,34,75.3,34.6z"/><path fill="#67ac45" d="M69.7,47.9c-0.4-3.7-1.7-7.1-3.8-10c-0.8-1-1.6-2-2.6-2.9c-3.1-2.8-7.1-4.7-11.5-5.2c-0.7-0.1-1.3-0.1-2-0.1 s-1.3,0-2,0.1c-9.5,0.9-17,8.5-17.9,18c-0.1,0.6-0.1,1.3-0.1,1.9c0,0.7,0,1.4,0.1,2.1c1,9.4,8.5,16.9,17.9,17.8 c0.7,0.1,1.3,0.1,2,0.1s1.3,0,2-0.1c9.4-0.9,16.9-8.4,17.9-17.8c0.1-0.7,0.1-1.4,0.1-2.1C69.8,49.2,69.8,48.5,69.7,47.9z M51.8,65.7c-0.7,0.1-1.3,0.1-2,0.1s-1.3,0-2-0.1c-7.2-0.9-12.9-6.6-13.9-13.8c-0.1-0.7-0.1-1.4-0.1-2.1c0-0.7,0-1.3,0.1-1.9 c0.9-7.3,6.6-13,13.9-14c0.7-0.1,1.3-0.1,2-0.1s1.3,0,2,0.1c3.3,0.4,6.3,1.8,8.7,4c1,0.9,1.8,1.8,2.6,2.9c1.4,2.1,2.3,4.5,2.7,7.1 c0.1,0.6,0.1,1.3,0.1,1.9c0,0.7,0,1.4-0.1,2.1C64.7,59.1,59,64.8,51.8,65.7z"/><rect x="24.6" y="47.9" fill="#67ac45" width="50.3" height="4"/><rect x="47.8" y="24.8" fill="#67ac45" width="4" height="50.3"/></svg>'
        ),
      otherStyleSVG:
        'data:image/svg+xml;utf8,' +
        encodeURIComponent(
          '<svg xmlns="http://www.w3.org/2000/svg" width="100px" height="100px"><circle fill="#67ac45" cx="50" cy="49.9" r="20"/><rect x="63" y="48" width="11.9" height="4"/><rect x="25.1" y="48" width="11.9" height="4"/><rect x="48" y="63" width="4" height="11.9"/><rect x="48" y="25.1" width="4" height="11.9"/></svg>'
        ),
      probingStyleSVG:
        'data:image/svg+xml;utf8,' +
        encodeURIComponent(
          '<svg xmlns="http://www.w3.org/2000/svg" width="100px" height="100px"><polygon fill="#67ac45" points="72.6,32.4 49.7,71.1 49.6,71.1 49.6,71.1 26.7,32.4 \t"/><rect x="47.9" y="25" width="4" height="12.2"/><rect x="47.9" y="62.9" width="4" height="12.2"/><rect x="62.7" y="48.1" width="12.2" height="4"/><rect x="25.1" y="48.1" width="12.2" height="4"/></svg>'
        ),
      holeStyleSVG:
        'data:image/svg+xml;utf8,' +
        encodeURIComponent(
          '<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="80" height="80"><style>tspan { white-space:pre }.shp0 { fill: none;stroke: #67ac45;stroke-width: 3.5 } .shp1 { fill: #67ac45 } </style><path class="shp0" d="M24 23L76 23L76 76L24 76L24 23Z" /><path class="shp1" d="M25.07 77.32L22.93 75.22L74.59 22.39L76.74 24.49L25.07 77.32ZM23.1 24.66L25.23 22.55L75.9 73.88L73.77 75.99L23.1 24.66Z" /></svg>'
        ),
      findingSiteStyleSVG:
        'data:image/svg+xml;utf8,' +
        encodeURIComponent(
          '<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="100" height="100"><style>tspan { white-space:pre }.shp0 { fill: #67ac45 } </style><path class="shp0" d="M49.5 62C42.59 62 37 56.41 37 49.5C37 42.59 42.59 37 49.5 37C56.41 37 62 42.59 62 49.5C62 56.41 56.41 62 49.5 62Z" /></svg>'
        ),
      monitoringWellStyleSVG:
        'data:image/svg+xml;utf8,' +
        encodeURIComponent(
          '<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="80" height="80"><style>tspan { white-space:pre }.shp0 { fill: #67ac45 } </style><path fill-rule="evenodd" class="shp0" d="M80.1 33.6C80 34.2 79.8 34.9 79.4 35.5C79 36.1 78.6 36.7 78.1 37.2C77.4 37.9 76.7 38.4 76 38.7C75.3 39 74.6 39.2 73.9 39.1C73.2 39.1 72.5 38.9 71.8 38.5C71.1 38.2 70.4 37.7 69.8 37L68.3 35.6L63.1 40.8C62.3 39.7 61.5 38.8 60.5 37.9L73.6 24.8L78.4 29.6C79.1 30.3 79.6 31 79.8 31.7C80 32.4 80.1 33.1 80.1 33.6ZM76.4 32.6C76.5 31.9 76.2 31.3 75.5 30.6L74.4 29.5L70.3 33.6L71.5 34.8C72 35.3 72.6 35.6 73.3 35.6C73.9 35.6 74.6 35.3 75.3 34.6C75.9 34 76.3 33.3 76.4 32.6Z" /><path fill-rule="evenodd" class="shp0" d="M69.8 49.7C69.8 50.4 69.8 51.1 69.7 51.8C68.7 61.2 61.2 68.7 51.8 69.6C51.1 69.7 50.5 69.7 49.8 69.7C49.1 69.7 48.5 69.7 47.8 69.6C38.4 68.7 30.9 61.2 29.9 51.8C29.8 51.1 29.8 50.4 29.8 49.7C29.8 49.1 29.8 48.4 29.9 47.8C30.8 38.3 38.3 30.7 47.8 29.8C48.5 29.7 49.1 29.7 49.8 29.7C50.5 29.7 51.1 29.7 51.8 29.8C56.2 30.3 60.2 32.2 63.3 35C64.3 35.9 65.1 36.9 65.9 37.9C68 40.8 69.3 44.2 69.7 47.9C69.8 48.5 69.8 49.2 69.8 49.7ZM65.8 51.9C65.9 51.2 65.9 50.5 65.9 49.8C65.9 49.2 65.9 48.5 65.8 47.9C65.4 45.3 64.5 42.9 63.1 40.8C62.3 39.7 61.5 38.8 60.5 37.9C58.1 35.7 55.1 34.3 51.8 33.9C51.1 33.8 50.5 33.8 49.8 33.8C49.1 33.8 48.5 33.8 47.8 33.9C40.5 34.9 34.8 40.6 33.9 47.9C33.8 48.5 33.8 49.1 33.8 49.8C33.8 50.5 33.8 51.2 33.9 51.9C34.9 59.1 40.6 64.8 47.8 65.7C48.5 65.8 49.1 65.8 49.8 65.8C50.5 65.8 51.1 65.8 51.8 65.7C59 64.8 64.7 59.1 65.8 51.9Z" /><path class="shp0" d="M24.6 47.9L74.9 47.9L74.9 51.9L24.6 51.9L24.6 47.9Z" /><path class="shp0" d="M47.8 24.8L51.8 24.8L51.8 75.1L47.8 75.1L47.8 24.8Z" /><path class="shp0" d="M70.8 20.92L70.8 24.92L22.28 24.92L22.28 20.92L70.8 20.92ZM22 21.33L26 21.33L26 77.47L22 77.47L22 21.33ZM22.6 77.53L22.6 73.53L77.92 73.53L77.92 77.53L22.6 77.53ZM78 77.53L74 77.53L74 41.46L78 41.46L78 77.53Z" /></svg>'
        ),
      holeBoreholeStyleSVG:
        'data:image/svg+xml;utf8,' +
        encodeURIComponent(
          '<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="80" height="80"><style>tspan { white-space:pre }.shp0 { fill: #67ac45 } .shp1 { fill: none;stroke: #67ac45;stroke-width: 3.5 } </style><path fill-rule="evenodd" class="shp0" d="M69.8 49.7C69.8 50.4 69.8 51.1 69.7 51.8C68.7 61.2 61.2 68.7 51.8 69.6C51.1 69.7 50.5 69.7 49.8 69.7C49.1 69.7 48.5 69.7 47.8 69.6C38.4 68.7 30.9 61.2 29.9 51.8C29.8 51.1 29.8 50.4 29.8 49.7C29.8 49.1 29.8 48.4 29.9 47.8C30.8 38.3 38.3 30.7 47.8 29.8C48.5 29.7 49.1 29.7 49.8 29.7C50.5 29.7 51.1 29.7 51.8 29.8C56.2 30.3 60.2 32.2 63.3 35C64.3 35.9 65.1 36.9 65.9 37.9C68 40.8 69.3 44.2 69.7 47.9C69.8 48.5 69.8 49.2 69.8 49.7ZM65.8 51.9C65.9 51.2 65.9 50.5 65.9 49.8C65.9 49.2 65.9 48.5 65.8 47.9C65.4 45.3 64.5 42.9 63.1 40.8C62.3 39.7 61.5 38.8 60.5 37.9C58.1 35.7 55.1 34.3 51.8 33.9C51.1 33.8 50.5 33.8 49.8 33.8C49.1 33.8 48.5 33.8 47.8 33.9C40.5 34.9 34.8 40.6 33.9 47.9C33.8 48.5 33.8 49.1 33.8 49.8C33.8 50.5 33.8 51.2 33.9 51.9C34.9 59.1 40.6 64.8 47.8 65.7C48.5 65.8 49.1 65.8 49.8 65.8C50.5 65.8 51.1 65.8 51.8 65.7C59 64.8 64.7 59.1 65.8 51.9Z" /><path class="shp0" d="M24.6 47.9L74.9 47.9L74.9 51.9L24.6 51.9L24.6 47.9Z" /><path class="shp0" d="M47.8 24.8L51.8 24.8L51.8 75.1L47.8 75.1L47.8 24.8Z" /><path class="shp1" d="M24 23L76 23L76 76L24 76L24 23Z" /></svg>'
        )
    }
  },
  mounted () {
    this.initializeMap()
    this.initializeMarkers()
    this.centerMap()
  },
  methods: {
    initializeMap () {
      this.map = new olMap({
        target: 'SampleMap' + this.sampleNumber,
        controls: defaultControls().extend([
          new FullScreen({
            source: 'Fullscreen' + this.sampleNumber
          })
        ]),
        layers: [
          new TileLayer({
            source: new OSM(),
            visible: true
          })
        ],
        view: new olView({
          projection: 'EPSG:3857',
          center: fromLonLat([0, 0]),
          zoom: this.zoomLevel
        }) })
    },
    centerMap () {
      const bounds = boundingExtent(this.sampleMeasurementPointMarkers.map(mp => fromLonLat([mp.X, mp.Y])))
      const bufferedBound = buffer(bounds, this.bufferBoundaryMeters)
      this.map.getView().fit(bufferedBound)
    },
    initializeMarkers () {
      const localMarkers = this.sampleMeasurementPointMarkers.map(mp => {
        return this.createMarker(mp, true)
      })
      const otherMarkers = this.otherMeasurementPointMarkers.map(mp => {
        return this.createMarker(mp, false)
      })
      const allMarkers = [...localMarkers, ...otherMarkers]
      var vectorLayer = new olLayerVector({
        source: new olSourceVector({
          features: allMarkers
        }),
        declutter: false
      })
      this.map.addLayer(vectorLayer)
    },
    setMarkerSvg (measurementPointTypeCode) {
      if (['V', 'B'].includes(measurementPointTypeCode)) {
        return this.defaultStyleSVG
      } else if (measurementPointTypeCode === 'P') {
        return this.gaugingTubeStyleSVG
      } else if (['A', 'D', 'E'].includes(measurementPointTypeCode)) {
        return this.defaultStyleSVG
      } else if (['G', 'K', 'L', 'R', 'S', 'U', 'W'].includes(measurementPointTypeCode)) {
        return this.otherStyleSVG
      } else if (['C', 'O'].includes(measurementPointTypeCode)) {
        return this.probingStyleSVG
      } else if (measurementPointTypeCode === '35') {
        return this.holeBoreholeStyleSVG
      } else if (measurementPointTypeCode === '36') {
        return this.monitoringWellStyleSVG
      } else if (measurementPointTypeCode === '37') {
        return this.findingSiteStyleSVG
      } else {
        return this.defaultStyleSVG
      }
    },
    createMarker (measurementPoint, local) {
      const markerColor = local ? this.localMarkerColor : this.otherMarkerColor
      const svgStyle = this.setMarkerSvg(measurementPoint.TypeCode)

      const featureStyle = new olStyleStyle({
        zIndex: local ? 20 : 10,
        image: new olStyleIcon({
          src: svgStyle,
          scale: local ? 0.6 : 0.5,
          color: markerColor
        }),
        text: new olStyleText({
          text: measurementPoint.Name,
          fill: new olStyleFill({
            color: markerColor
          }),
          offsetY: -20,
          stroke: new olStyleStroke({
            color: markerColor,
            width: local ? 1.2 : 1
          }),
          font: '14px sans-serif',
          overflow: true
        })
      })
      const marker = new olFeature({
        geometry: new olGeomPoint(fromLonLat([measurementPoint.X, measurementPoint.Y]))
      })

      marker.setStyle(featureStyle)
      marker.setId(measurementPoint.Name)
      return marker
    }
  } }
</script>
<style>
@import "../openLayers/ol-custom.css";
  .analysis-sample-map {
    background-color: #515151;
    position: absolute;
    margin: 10px 0 0 0;
    padding: 0;
    right:   0%;
    height: 90%;
    width: 100%;
    border-radius: 10px;
  }

  .analysis-sample-map > .ol-viewport {
    border-radius: 10px !important;
  }
  .analysis-sample-map .ol-full-screen {
      right: 1.5em;
  }
  .full-screen-suggested-sample-card-map:-webkit-full-screen {
    height: 100%;
    margin: 0;
  }
  .full-screen-suggested-sample-card-map:-ms-fullscreen {
    height: 100%;
  }

  .full-screen-suggested-sample-card-map:fullscreen {
    height: 100%;
  }

  .full-screen-suggested-sample-card-map {
    margin-bottom: 10px;
    width: 100%;
    height: 250px;
  }
</style>
