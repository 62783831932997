<template>
    <div class="panel panel-default">
        <div class="panel-heading title" :class="classColor" :style="{cursor: collapsible ? 'pointer' : 'default' }" @click="toggleOpen">
          <div class="panel-title-container">
            <span
              v-if="collapsible && showCollapsedIcon"
              class="collapsible-icon glyphicon"
              :class="open ? 'glyphicon-menu-up' : 'glyphicon-menu-down'"
              />
            <h4 v-if="!hasTitleSlot || title !== ''" >
              {{title}}
            </h4>
            <slot  v-else name="title"></slot>
          </div>
          <div class="content-end-header">
            <slot name="contentEndHeader"></slot>
          </div>
        </div>
        <div v-if="!collapsible || open" class="panel-body" >
            <slot name="content"></slot>
        </div>
    </div>
</template>
<script>
export default {
  name: 'TiPanel',
  props: {
    title: {
      type: String,
      required: false,
      default () {
        return ''
      }
    },
    collapsible: {
      type: Boolean,
      required: false,
      default () {
        return true
      }
    },
    secondary: {
      type: Boolean,
      required: false,
      default () {
        return false
      }
    },
    tertiary: {
      type: Boolean,
      required: false,
      default () {
        return false
      }
    },
    showCollapsedIcon: {
      type: Boolean,
      required: false,
      default () {
        return false
      }
    }
  },
  data () {
    return {
      open: false
    }
  },
  methods: {
    toggleOpen () {
      if (this.collapsible) {
        this.open = !this.open
      }
    }
  },
  computed: {
    classColor() {
      return this.secondary ? 'secondary' : this.tertiary ? 'tertiary' : 'primary'
    },
    hasTitleSlot() {
      return !!this.$slots.title
    }
  }
}
</script>
<style scoped>
.panel-body {
  padding: 0;
}

.panel-group {
  margin-bottom: 0;
}
.panel {
  margin: 20px;
  padding: 0px;
  position: relative;
}
.panel-body > div,
.panel-body > form {
  padding: 15px
}
.panel-body > pre {
  margin: 15px
}
.panel-heading {
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: break-spaces;
  height: 40px;
  width: 100%;
}
.panel-heading * {
  max-height: 40px;
  overflow: hidden;
}
.title {
  font-size: 1.1em;
  font-weight: bold;
}
.alert {
  margin-bottom: 0;
}
.btn {
  background: #fff;
}
.btn-submit {
  background: #67ac45;
  color: #fff;
}
.btn-submit:hover {
  border-color: #3f572e;
}
input.form-control {
  border-radius: 0;
}
.form-group p {
  padding-top: 7px;
}
.alert {
    margin: 0 15px 15px 15px;
}
.secondary {
  background: #447CC6;
}

.primary {
  background: #67ac45;
}
.tertiary {
  background: #F5B400;
}
.content-end-header {
  justify-self: flex-end;
}
.collapsible-icon {
  margin-right: 5px;
}
.panel-title-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
</style>
