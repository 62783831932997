<template>
    <TiPanel :title="$t('SuggestedSamples.Input')" :collapsible="false" secondary >
    <template #contentEndHeader>
      <div class="help-icon">
        <a
          :title="$t('SuggestedSamples.SuggestAnalysisSamplesHelp')"
          download
          :href="helpLinkHref"
        >
        <span class="glyphicons glyphicons-question-sign" />
        </a>
      </div>
    </template>
    <template #content>
        <form
        id="suggested-analysis-sample-input"
        class="form-horizontal row"
        >
        <div class="input-controls-container col-sm-5">
            <div class="form-group row">
            <label for="projectCode" class="control-label col-sm-6">
                {{$t('SuggestedSamples.ProjectCode')}}</label
            >
            <div class="col-sm-6">
                <p>
                {{ projectCode }}
                </p>
            </div>
            </div>
            <div class="form-group row">
            <label for="numberOfSamples" class="control-label col-sm-6">
              {{$t('SuggestedSamples.NumberOfSamples')}}
              </label>
              <div class="col-sm-5">
                <input
                id="number-of-samples"
                v-model="settings.numberOfSamples"
                type="number"
                class="form-control settings-input"
                min="1"
                max="2147483647"
                />
            </div>
            </div>
            <div class="form-group row">
            <label
                for="maximumNumberOfAdmixtures"
                class="control-label col-sm-6"
            >
            {{$t('SuggestedSamples.MaximumAdmixtures')}}
            </label>
            <div class="col-sm-5">
                <input
                id="maximum-number-of-admixtures"
                v-model="settings.maximumNumberOfAdmixtures"
                type="number"
                class="form-control settings-input"
                min="1"
                max="2147483647"
                />
            </div>
            </div>
            <div
            v-if="excludeFieldSamplesOlderThenDays > 0"
            class="form-group row"
            >
            <label
                for="excludeFieldSamplesOlderThenDays"
                class="control-label col-sm-6"
            >
            {{$t('SuggestedSamples.ExcludingJarsOlderThan')}}
            </label>
            <div class="col-sm-5">
                <p>{{ excludeFieldSamplesOlderThenDays }} {{$t('SuggestedSamples.days')}}</p>
            </div>
            </div>
            <div class="form-group row">
            <div class="col-sm-offset-6 col-sm-4">
                <div class="input-group">
                <div class="checkbox">
                <label
                    ><input
                    v-model="settings.isControlRun"
                    type="checkbox"
                    />
                    {{$t('SuggestedSamples.Controlrun')}}
                </label>
                </div>
                </div>
            </div>
            </div>
            <SuggestAnalysisSampleDepthInputs
            :lowerDepthValid="validation.lowerDepthValid"
            :maxDepthValid="validation.maxDepthValid"
            :upperSoilDepth="settings.upperSoilDepth"
            :lowerSoilDepth="settings.lowerSoilDepth"
            :maxSoilDepth="settings.maxSoilDepth"
            :topSoilMinimum="settings.topSoilMinimum"
            :midSoilMinimum="settings.midSoilMinimum"
            :lowerSoilMinimum="settings.lowerSoilMinimum"
            @update:upperSoilDepth="upperSoilDepthChanged"
            @update:lowerSoilDepth="lowerSoilDepthChanged"
            @update:maxSoilDepth="maxSoilDepthChanged"
            @update:topSoilMinimum="topSoilMinimumChanged"
            @update:midSoilMinimum="midSoilMinimumChanged"
            @update:lowerSoilMinimum="lowerSoilMinimumChanged"
            />
        </div>
        <div class="col-sm-7">
          <SuggestAnalysisSampleMpTable
            v-if="initialized"
            @updateSelectedMpIds="updateSelectedMpIds"
          />
        </div>
        </form>
        <div class="pin-to-bottom-right-corner-container"
        :class="excludeFieldSamplesOlderThenDays > 0 ? 'compact-button-error-container': 'button-error-container'">
          <div
            v-if="validation.depthErrorMessage !== ''"
            class="form-group row has-error"
            :class="excludeFieldSamplesOlderThenDays > 0 ? 'error-message-compact': 'error-message'"
            >
              <span class="help-block">
              {{ validation.depthErrorMessage }}
              </span>
          </div>
          <div class="submit-reset-button-container">
              <button
                  type="button"
                  class="btn btn-default"
                  @click="refresh">
                  {{$t('SuggestedSamples.Refresh')}}
              </button>
              <button
                type="button"
                class="btn btn-submit"
                :disabled="inputFormHasError"
                @click="submit"
            >
            {{$t('SuggestedSamples.SuggestAnalysisSamples')}}
            </button>
          </div>
        </div>
    </template>
    </TiPanel>
</template>

<script>
import TiPanel from '@/components/panels/TiPanel'
import SuggestAnalysisSampleDepthInputs from '@/components/suggest-analysis-sample/SuggestAnalysisSampleDepthInputs.vue'
import SuggestAnalysisSampleMpTable from '@/components/suggest-analysis-sample/SuggestAnalysisSampleMpTable.vue'
import config from '@/configurations/config.js'
import Vue from 'vue'

export default {
  name: 'SuggestAnalysisSamplesInputContainer',
  components: {
    TiPanel,
    SuggestAnalysisSampleDepthInputs,
    SuggestAnalysisSampleMpTable
  },
  props: {
    projectCode: {
      type: String,
      required: true
    },
    initialized: {
      type: Boolean,
      required: true
    },
    excludeFieldSamplesOlderThenDays: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      validation: {
        depthErrorMessage: '',
        lowerDepthValid: true,
        maxDepthValid: true
      },
      settings: {
        numberOfSamples: 25,
        maximumNumberOfAdmixtures: 4,
        upperSoilDepth: 50,
        lowerSoilDepth: 150,
        maxSoilDepth: 0,
        topSoilMinimum: 1,
        midSoilMinimum: 1,
        lowerSoilMinimum: 1,
        isControlRun: false
      },
      lang: Vue.config.lang,
      userManualFileName: {
        'nl': 'Analysemonsters-voorstellen-gebruikershandleiding.pdf',
        'en': 'Suggest-analysis-sample-user-manual.pdf'
      }
    }
  },
  computed: {
    helpLinkHref() {
      // only English and Dutch translations currently available
      const lang = ['nl', 'en'].includes(this.lang) ? this.lang : 'en'
      return `${config.root}assets/suggestAnalysisSample/${this.userManualFileName[lang]}`
    },

    inputFormHasError() {
      return !this.initialized || this.validation.depthErrorMessage !== ''
    }
  },
  watch: {
    settings: {
      deep: true,
      handler(value) {
        if (!value) {
          return
        }
        if (value.lowerSoilDepth !== '' || value.upperSoilDepth !== '') {
          this.validateInputSoilDepth()
        }
      }
    }
  },
  methods: {
    upperSoilDepthChanged(upperDepth) {
      this.settings.upperSoilDepth = upperDepth
    },
    lowerSoilDepthChanged(lowerDepth) {
      this.settings.lowerSoilDepth = lowerDepth
    },
    maxSoilDepthChanged(maxDepth) {
      this.settings.maxSoilDepth = maxDepth
    },
    topSoilMinimumChanged(topSoilMinimum) {
      this.settings.topSoilMinimum = topSoilMinimum
    },
    midSoilMinimumChanged(midSoilMinimum) {
      this.settings.midSoilMinimum = midSoilMinimum
    },
    lowerSoilMinimumChanged(lowerSoilMinimum) {
      this.settings.lowerSoilMinimum = lowerSoilMinimum
    },

    validateInputSoilDepth() {
      if (this.settings.upperSoilDepth >= this.settings.lowerSoilDepth) {
        this.validation.depthErrorMessage =
          this.$t('SuggestedSamples.LowerSoilMustBeHigherThanUpperSoil')
        this.validation.lowerDepthValid = false
        return
      }
      if (this.settings.maxSoilDepth !== 0 && this.settings.maxSoilDepth <= this.settings.lowerSoilDepth) {
        this.validation.depthErrorMessage =
        this.$t('SuggestedSamples.MaxDepthMustBeHigherThanLowerSoil')
        this.validation.maxDepthValid = false
        return
      }
      if (this.settings.topSoilMinimum === -1 &&
        this.settings.midSoilMinimum === -1 &&
        this.settings.lowerSoilMinimum === -1) {
        this.validation.depthErrorMessage =
        this.$t('SuggestedSamples.MustAllow1SoilDepthTrajectory')
        return
      }

      this.validation.depthErrorMessage = ''
      this.validation.maxDepthValid = true
      this.validation.lowerDepthValid = true
    },

    async submit() {
      this.$emit('submit', this.getParsedInputSettings())
    },

    async refresh() {
      this.$emit('refresh')
    },
    updateSelectedMpIds(mpIdsArray) {
      if (mpIdsArray.length === 0) {
        this.validation.depthErrorMessage =
          this.$t('SuggestedSamples.MustHaveAtLeastOneMeasurementpointSelected')
        return
      } else if (this.validation.depthErrorMessage) {
        this.validateInputSoilDepth()
      }
      this.$emit('updateSelectedMpIds', mpIdsArray)
    },
    getParsedInputSettings() {
      return {
        numberOfSamples: parseInt(this.settings.numberOfSamples),
        maximumNumberOfAdmixtures: parseInt(
          this.settings.maximumNumberOfAdmixtures
        ),
        upperSoilDepth: parseInt(this.settings.upperSoilDepth),
        lowerSoilDepth: parseInt(this.settings.lowerSoilDepth),
        maxSoilDepth: parseInt(this.settings.maxSoilDepth),
        topSoilMinimum: parseInt(this.settings.topSoilMinimum),
        midSoilMinimum: parseInt(this.settings.midSoilMinimum),
        lowerSoilMinimum: parseInt(this.settings.lowerSoilMinimum),
        isControlRun: this.settings.isControlRun
      }
    }
  }
}
</script>
<style scoped >
.btn {
  margin: 5px;
  outline: none;
}
.settings-input {
  width: 65px;
  padding: 0x;
  border-radius: 2px;
}
.pin-to-bottom-right-corner-container {
  position: absolute;
  bottom: 0;
  margin: 0;
  right: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.compact-button-error-container {
  flex-direction: row;
}
.button-error-container {
  flex-direction: column;
}
#suggested-analysis-sample-input {
  padding-top: 5px;
  min-width: 430px;
  overflow: hidden;
}
.submit-reset-button-container {
  display: flex;
}
.error-message-compact {
  width: 60%;
  white-space: normal !important;
  padding-left: 10px;
}
.error-message {
  align-self: center;
}
.input-controls-container {
  overflow: hidden;
  padding-left: 40px;
}
.help-block {
    white-space: normal;
    padding: 2px 2px 0 8px;
    margin-bottom: 0px;
}
.help-icon {
  cursor: pointer;
  color: #fff;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: large;
}
.help-icon * {
  color: #fff;
}

</style>
