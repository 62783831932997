<template>
  <DxDataGrid
    id="suggest-analysis-sample-mp-table"
    :data-source="measurementPointTableData"
    :show-borders="true"
    :show-row-lines="true"
    key-expr="MpID"
    :allow-column-resizing="true"
    column-resizing-mode="nextColumn"
    :column-min-width="40"
    @selection-changed="rowsVisibleChanged"
    @content-ready="rowsVisibleChanged"
    @toolbar-preparing="toolbarPreparing"
  >
    <DxPaging :enabled="false" />
    <DxGrouping :auto-expand-all="false" />
    <DxGroupPanel :visible="true" />
    <DxFilterRow :visible="true" />
    <DxHeaderFilter :visible="true" />
    <DxSearchPanel
      :visible="true"
      :width="180"
      :placeholder="$t('SuggestedSamples.Search')"
    />
    <DxFilterPanel :visible="true"/>
    <DxSelection
      mode="multiple"
      show-check-boxes-mode="always"
    />
    <DxColumn
      data-field="MpName"
      :allow-filtering="true"
      :caption="$t(`SuggestedSamples.MpColumnName`)"
    />
    <DxColumn
      data-field="MpDate"
      :allow-filtering="true"
      data-type="date"
      :caption="$t(`SuggestedSamples.MpColumnDate`)"
      :format="(value) => formatCellValue(value)"
    />
    <DxColumn
      data-field="MpTypeCode"
      :allow-filtering="true"
      :caption="$t(`SuggestedSamples.MpColumnType`)"
    >
      <DxLookup display-expr="GcDescription" value-expr="GcCode" :data-source="mpTypeCodeList" />
    </DxColumn>
    <DxColumn
      data-field="MpGroundLevelTypeCode"
      :allow-filtering="true"
      :caption="$t(`SuggestedSamples.MpColumnSurfaceLevel`)"
    >
      <DxLookup display-expr="GcDescription" value-expr="GcCode" :data-source="mpSurfaceTypeCodeList" />
    </DxColumn>
    <DxColumn
      data-field="SlID"
      :allow-filtering="true"
      :caption="$t(`SuggestedSamples.MpColumnSubLocationName`)"
    >
      <DxLookup display-expr="SlName" value-expr="SlID" :data-source="subLocationTableData" />
    </DxColumn>
    <template #suggest-mp-table-title>
      <h4 class="table-title">
        {{$t('SuggestedSamples.MeasurementpointToInlcude')}}
      </h4>
    </template>
  </DxDataGrid>
</template>

<script>
import deDevExtremeLocale from '@/locales/devExtreme/de'
import esDevExtremeLocale from '@/locales/devExtreme/es'
import frDevExtremeLocale from '@/locales/devExtreme/fr'
import itDevExtremeLocale from '@/locales/devExtreme/it'
import nlDevExtremeLocale from '@/locales/devExtreme/nl'
import Cookie from '@/utils/cacheProviders/cookieCacheProvider'
import { DxColumn, DxDataGrid, DxFilterPanel, DxFilterRow, DxGrouping, DxGroupPanel, DxHeaderFilter, DxLookup, DxPaging, DxSearchPanel, DxSelection } from 'devextreme-vue/data-grid'
import { formatDate, loadMessages, locale } from 'devextreme/localization'
export default {
  name: 'SuggestAnalysisSampleMpTable',
  components: {
    DxColumn,
    DxDataGrid,
    DxSelection,
    DxFilterRow,
    DxSearchPanel,
    DxHeaderFilter,
    DxGroupPanel,
    DxGrouping,
    DxLookup,
    DxPaging,
    DxFilterPanel
  },
  data() {
    return {
      initialized: false,
      newTableRows: []
    }
  },
  created () {
    if (this.language && this.language !== 'en') {
      switch (this.language) {
        case 'nl':
          loadMessages(nlDevExtremeLocale)
          break
        case 'fr':
          loadMessages(frDevExtremeLocale)
          break
        case 'de':
          loadMessages(deDevExtremeLocale)
          break
        case 'es':
          loadMessages(esDevExtremeLocale)
          break
        case 'it':
          loadMessages(itDevExtremeLocale)
          break
      }
      locale(this.language)
    }
  },
  computed: {
    language() {
      return Cookie.get('language')
    },
    measurementPointTableData() {
      if (!this.$store.state.currentProject || !this.$store.state.currentProject.VeldData || !this.$store.state.currentProject.VeldData.tblMeasurementPoints) {
        return []
      }
      if (!Array.isArray(this.$store.state.currentProject.VeldData.tblMeasurementPoints)) {
        return [this.$store.state.currentProject.VeldData.tblMeasurementPoints]
      }
      return this.$store.state.currentProject.VeldData.tblMeasurementPoints
    },
    subLocationTableData() {
      if (!this.$store.state.currentProject || !this.$store.state.currentProject.VeldData || !this.$store.state.currentProject.VeldData.tblSublocations) {
        return []
      }
      if (!Array.isArray(this.$store.state.currentProject.VeldData.tblSublocations)) {
        return [this.$store.state.currentProject.VeldData.tblSublocations]
      }
      return this.$store.state.currentProject.VeldData.tblSublocations
    },
    mpTypeCodeList() {
      if (!this.$store.state.codeLists || !this.$store.state.codeLists.MeasurementObjectType) {
        return []
      }
      return this.$store.state.codeLists.MeasurementObjectType
    },
    mpSurfaceTypeCodeList() {
      if (!this.$store.state.codeLists || !this.$store.state.codeLists.MeasurementObjectSurfaceType) {
        return []
      }
      return this.$store.state.codeLists.MeasurementObjectSurfaceType
    }
  },
  methods: {
    rowsVisibleChanged(e) {
      if (!this.initialized) {
        // initially all rows not selected
        this.initialized = true
        // select all will call this method again
        e.component.selectAll()
        return
      }

      const selectedRowIds = e.component.getSelectedRowKeys()
      let visibleRowIds = e.component.getVisibleRows()
        .map(row => row.data.MpID)
        .filter(id => selectedRowIds.includes(id) || this.newTableRows.includes(id))
      if (this.newTableRows.length > 0) {
        // If the user refreshes and a new Mp was added will be add to table as selected
        // Currently selected rows will stay selected
        this.newTableRows = []
        e.component.selectRows(visibleRowIds)
        return
      }
      if (!visibleRowIds) {
        // Shouldn't happen
        visibleRowIds = []
      }
      this.$emit('updateSelectedMpIds', visibleRowIds)
    },
    toolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
        location: 'before',
        template: 'suggest-mp-table-title'
      })
    },
    formatCellValue (value) {
      return formatDate(value, 'yyyy-MM-dd')
    }
  },
  watch: {
    'measurementPointTableData': {
      handler: function (currentArray, previousArray) {
        // In case the off chance that the user refreshes the data and a new mp has been added
        // Does not fire on init
        const previousRowIds = previousArray.map(row => row.MpID)
        const currentRowIds = currentArray.map(row => row.MpID)
        this.newTableRows = currentRowIds.filter(id => !previousRowIds.includes(id))
      }
    }
  }
}
</script>
<style>
    #suggest-analysis-sample-mp-table {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        max-height: 395px;
        font-family: 'Segoe UI','Open Sans', sans-serif;
    }
    #suggest-analysis-sample-mp-table .dx-command-select {
      width: 25px;
      min-width: 25px;
    }
    #suggest-analysis-sample-mp-table .dx-datagrid .dx-header-filter-empty {
      color: #447cc6;
    }

    #suggest-analysis-sample-mp-table .dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > td {
      background-color: #447cc61c;
    }
    #suggest-analysis-sample-mp-table .dx-group-panel-message {
      margin: 0;
      padding: 0;
      height: 100%;
      line-height: 18px;
    }
    #suggest-analysis-sample-mp-table .table-title {
      padding: 0;
      margin: 0;
    }
    #suggest-analysis-sample-mp-table .dx-toolbar-after {
      padding-top: 8px;
    }
    #suggest-analysis-sample-mp-table .dx-checkbox-checked .dx-checkbox-icon {
      color: #447cc6;
    }

    #suggest-analysis-sample-mp-table .dx-toolbar-before {
      display: flex;
      flex-direction: column;
    }
    #suggest-analysis-sample-mp-table .dx-toolbar-before .dx-toolbar-item {
      flex: 1;
      height: 50%;
      width: 350px;
      max-width: 350px !important;
    }
    #suggest-analysis-sample-mp-table .dx-datagrid-group-panel .dx-group-panel-item  {
      padding: 3px;
    }
</style>
