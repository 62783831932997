
<template>
  <div class="body">
    <app-header />
    <div class="suggest-analysis-content-container">
      <div v-if="userIsAuthorized?.userHasFeatureWord === false && userIsAuthorized?.userHasFunction === false" class="row">
        <div class="col-sm-4"></div>
        <div class="col-sm-4 unauthorized">
          <div class="panel panel-default">
            <div class="alert alert-warning" role="alert">
              {{ $t('SuggestedSamples.UnauthorizedNeedFeatureWord') }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="userIsAuthorized?.userHasFeatureWord === true && userIsAuthorized?.userHasFunction === false" class="row">
        <div class="col-sm-4"></div>
        <div class="col-sm-4 unauthorized">
          <div class="panel panel-default">
            <div class="alert alert-warning" role="alert">
              {{ $t('SuggestedSamples.UnauthorizedNeedFunction') }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="userIsAuthorized?.userHasFeatureWord === true && userIsAuthorized?.userHasFunction === true" class="row">
        <div class="col-sm-8 suggested-samples-input-output-column input-column">
          <SuggestAnalysisSamplesInputContainer :projectCode="projectCode" :initialized="initialized" :excludeFieldSamplesOlderThenDays="parameterExcludeFieldSamplesOlderThenDays" @submit="getSuggestedAnalysisSamples" @refresh="getProjectData" @updateSelectedMpIds="updateSelectedMpIds" />
        </div>
        <div class="col-sm-3 suggested-samples-input-output-column">
          <SuggestAnalysisSamplesOutputContainer v-if="showSampleOutputPanel" :projectCode="projectCode" :suggestedAnalysisSamplesResults="results.suggestedSamples" :codeLists="codeLists" :veldData="this.project.VeldData" :userHasDeveloperLicense="userHasDeveloperLicense"
            :resultLog="results.resultLog" />
        </div>
        <div class="col-sm-11 suggested-sample-column">
          <SuggestAnalysisSampleCard
            v-for="suggestedAnalysisSample in results.formattedSuggestedSamples"
            :key="suggestedAnalysisSample.sampleNumber"
            :measurementPointFeatures="measurementPointFeatures"
            :suggestedAnalysisSample="suggestedAnalysisSample"
            :allMeasurementPoints="selectedFormattedMeasurementPoints"
            @createSample="startCreatingSample"
            @switchJar="switchJar" />
        </div>
        <div class="modal-container">
          <CreateAnalysisSampleModal :show="showCreateSampleModal" :defaultName="newAnalysisSample.sampleName" :sampleNameTaken="newAnalysisSampleNameExists" :matrixCode="newAnalysisSample.matrixCode" @completeCreatingSample="completeCreatingSample" @cancelCreatingSample="cancelCreatingSample"
            @update:sampleName="creatingSampleNameChange" @update:sampleMatrixCode="creatingSampleMatrixCodeChange" />
          <confirm-modal :show="showSwitchJarModal" :title-label="$t('SuggestedSamples.SwitchSelectJar')" :confirm-button-label="$t('SuggestedSamples.Switch')" :cancel-button-label="$t('button.cancel')" @close-modal="cancelJarSwitch" @confirm-button-pressed="confirmJarSwitch">{{
            $t('SuggestedSamples.SwitchMessage') }}</confirm-modal>
        </div>
      </div>
    </div>
    <app-footer />
    <nv-loading :show="loading"></nv-loading>
  </div>
</template>

<script>
import ConfirmModal from '@/components/modal/confirmModal'
import CreateAnalysisSampleModal from '@/components/suggest-analysis-sample/CreateAnalysisSampleModal.vue'
import SuggestAnalysisSampleCard from '@/components/suggest-analysis-sample/SuggestAnalysisSampleCard.vue'
import SuggestAnalysisSamplesInputContainer from '@/components/suggest-analysis-sample/SuggestAnalysisSamplesInputContainer.vue'
import SuggestAnalysisSamplesOutputContainer from '@/components/suggest-analysis-sample/SuggestAnalysisSamplesOutputContainer.vue'
import AppFooter from '@/components/_shared/footer'
import AppHeader from '@/components/_shared/header'
import nvLoading from '@/components/_shared/loading'
import Config from '@/configurations/config'
import config from '@/configurations/config.js'
import Popup from '@/utils/popup'
import guidUtils from '@/utils/guidUtils'
import stringUtils from '@/utils/stringUtils'
import sortUtils from '@/utils/sortUtils'
import moment from 'moment'
import Vue from 'vue'
import cookie from 'vue-cookie'

const TiPopup = Popup[Config.platform].default
export default {
  name: 'SuggestAnalysisSamples',
  components: {
    AppHeader,
    AppFooter,
    nvLoading,
    SuggestAnalysisSampleCard,
    SuggestAnalysisSamplesInputContainer,
    SuggestAnalysisSamplesOutputContainer,
    CreateAnalysisSampleModal,
    ConfirmModal
  },
  data() {
    return {
      userIsAuthorized: {
        userHasFeatureWord: undefined,
        userHasFunction: undefined
      },
      root: config.root,
      lang: Vue.config.lang,
      developerLicenseNumbers: ['7', '10'],
      loading: false,
      selectedMeasurementPointIds: [],
      measurementPointFeatures: [],
      results: {
        suggestedSamples: [],
        resultLog: [],
        formattedSampleJars: [],
        formattedSuggestedSamples: []
      },
      requiredCodeLists: {
        SoilType: 1,
        SandMedian: 2,
        GravelMedian: 3,
        SoilAdditive: 4,
        AdditiveGradiation: 5,
        SpecialCompound: 6,
        SpecialCompoundGradiation: 7,
        OdourType: 8,
        OdourIntensity: 9,
        MeasurementObjectSurfaceType: 11,
        OilWaterReaction: 12,
        MeasurementObjectType: 13,
        JarType: 21
      },
      selectedFormattedMeasurementPoints: [],
      initialized: false,
      showCreateSampleModal: false,
      newAnalysisSample: {
        suggestedSampleNumber: 0,
        sampleName: '',
        matrixCode: '1',
        jars: [],
        previousUserInputtedName: ''
      },
      isLatestProjectData: false,
      showSwitchJarModal: false,
      jarToSwitch: {
        newSelectedJarIndex: -1,
        newSelectedLayerIndex: -1,
        sampleJarIndex: -1,
        sampleIndex: -1
      },
      previouslyCreatedAnalysisSamples: []
      // eslint-disable-next-line no-undef
    }
  },
  computed: {
    projectCode() {
      const resultData = this.project
      return resultData &&
        resultData.VeldData &&
        resultData.VeldData.tblProjects &&
        resultData.VeldData.tblProjects.PrCode
        ? resultData.VeldData.tblProjects.PrCode
        : ''
    },
    parameterExcludeFieldSamplesOlderThenDays: function () {
      return parseInt(this.$route.params.AvailableSampleDays)
    },

    parameterProjectId: function () {
      return parseInt(this.$route.params.ProjectID)
    },

    project() {
      return this.$store.state.currentProject
    },

    userHasDeveloperLicense() {
      const licenseNumber = cookie.get('licensenumber')
      return this.initialized && this.developerLicenseNumbers.includes(licenseNumber)
    },

    showSampleOutputPanel() {
      return (
        this.userHasDeveloperLicense || (this.results &&
          this.results.suggestedSamples &&
          this.results.suggestedSamples.length > 0)
      )
    },
    newAnalysisSampleNameExists() {
      if (this.newAnalysisSample.sampleName === '') {
        return false
      }
      if (
        !this.project ||
        !this.project.VeldData ||
        !this.project.VeldData.tblAnalysisSamples) {
        return false
      }
      return this.analysisSampleNameExists(this.newAnalysisSample.sampleName)
    },
    codeLists() {
      // Filters out codelists not relevant to suggested samples for output
      if (!this.$store.state.codeLists) {
        return []
      }
      const requeredKeyList = Object.keys(this.requiredCodeLists)

      return Object.keys(this.$store.state.codeLists)
        .filter(key => requeredKeyList.includes(key))
        .reduce((obj, key) => {
          obj[key] = this.$store.state.codeLists[key]
          return obj
        }, {})
    }
  },
  async mounted() {
    this.loading = true
    await this.initialize()
    this.loading = false
  },
  methods: {
    async initialize() {
      this.loading = true

      this.isAuthorizedForSampleSuggestion().then((isAuthorizedForSampleSuggestion) => {
        this.userIsAuthorized = isAuthorizedForSampleSuggestion
      })

      if (!this.validateRouteParameters()) {
        return
      }
      this.initialized = await this.getProjectData(true)
      this.loading = false
    },
    async isAuthorizedForSampleSuggestion() {
      var licenseNumber = cookie.get('licensenumber')
      if (!licenseNumber) {
        return {
          userHasFeatureWord: false,
          userHasFunction: false
        }
      }

      const featureWord = '1'
      return this.$store.dispatch('checkIfLicenseForUserIsAuthorizedForFeatureword', {
        featureWord: featureWord
      }).then((result) => {
        if (result === false) {
          return {
            userHasFeatureWord: false,
            userHasFunction: false
          }
        }

        const functionName = 'TOOL_SUGGEST_SAMPLES'
        return this.$store.dispatch('checkIfUserHasRoleFunction', {
          functionName: functionName
        }).then((result) => {
          return {
            userHasFeatureWord: true,
            userHasFunction: result
          }
        })
      })
    },
    async getProjectData(getCodeList = false) {
      const promises = []
      promises.push(
        this.$store.dispatch('fetchProjectWithLabData', {
          projectId: this.parameterProjectId,
          excludeFieldSamplesOlderThenDays:
            this.parameterExcludeFieldSamplesOlderThenDays
        })
      )
      if (getCodeList) {
        Object.keys(this.requiredCodeLists).forEach(codeListKey => {
          promises.push(this.$store
            .dispatch('getCodeListByCategoryIDOrCode', {
              categoryId: this.requiredCodeLists[codeListKey],
              language: this.lang
            })
            .then((codeList) => {
              this.$store.state.codeLists[codeListKey] = codeList.map(code => {
                return {
                  ...code,
                  GcDescription: stringUtils.capitalizeFirstLetter(code.GcDescription)
                }
              })
            }).catch(e => {
              TiPopup.popup(this.$t('SuggestedSamples.ProjectNotFound'))
            })
          )
        })
      }
      try {
        await Promise.all(promises)
        this.isLatestProjectData = true
        this.previouslyCreatedAnalysisSamples = []
      } catch (e) {
        TiPopup.popup(this.$t('SuggestedSamples.ProjectNotFound'))
        return false
      }

      if (!this.project) {
        TiPopup.popup(this.$t('SuggestedSamples.ProjectNotFound'))
        return false
      }
      // tblsJars is an object if only 1 item found, if two or more items found then it becomes an array
      if (
        !this.project ||
        !this.project.VeldData ||
        !this.project.VeldData.tblJars ||
        !(
          this.project.VeldData.tblJars.length ||
          this.project.VeldData.tblJars.JrGuid
        )
      ) {
        TiPopup.popup(this.$t('SuggestedSamples.ProjectContainsNoJars'))
      } else if (this.project.VeldData.tblMeasurementPoints) {
        this.initMeasurementPointFeatures()
        return true
      }
      return false
    },
    updateSelectedMpIds(mpIdsArray) {
      this.selectedMeasurementPointIds = mpIdsArray
    },
    validateRouteParameters() {
      if (
        isNaN(this.parameterProjectId) ||
        this.parameterProjectId < 1 ||
        this.parameterProjectId > 2147483647
      ) {
        TiPopup.popup(this.$t('SuggestedSamples.InvalidProjectIdParameter'))
        return false
      }

      if (
        isNaN(this.parameterExcludeFieldSamplesOlderThenDays) ||
        this.parameterExcludeFieldSamplesOlderThenDays < 0 ||
        this.parameterExcludeFieldSamplesOlderThenDays > 2147483647
      ) {
        TiPopup.popup(this.$t('SuggestedSamples.InvalidNumberOfDaysOldForFieldSamplesParameter'))
        return false
      }

      return true
    },

    async getSuggestedAnalysisSamples(settings) {
      this.loading = true
      try {
        if (!this.isLatestProjectData) {
          await this.getProjectData()
        }
        this.$store.dispatch('LogFrontEndActivity', ({ activityType: 314, activityMessage: `Started suggesting analaysis samples for project ${this.projectCode} with id ${this.parameterProjectId}` }))
        const response = await this.$store
          .dispatch('getSuggestedAnalysisSamples', {
            settings: {
              ...settings
            },
            project: this.getProjecWithOnlySelectedMeasurementpointsData()
          })
        let measurementPointsMemo = {}
        // memo temporary look up objects saves recalculating same mps, layers, jars
        this.results.suggestedSamples = response.suggestedSamples
        this.results.resultLog = response.resultLog && response.resultLog.records ? this.formatErrorMessages(response.resultLog.records) : []
        this.results.formattedSampleJars = this.formatJarsFromResult(response.jars, settings.upperSoilDepth, settings.lowerSoilDepth, measurementPointsMemo)
        this.results.formattedSuggestedSamples = this.formatSuggestedAnalysisSamples(response.suggestedSamples, measurementPointsMemo)
      } catch (e) {
        console.log(e)
        TiPopup.popup(this.$t('SuggestedSamples.ErrorGettingSuggestedSamples'))
      }
      this.loading = false
      this.setSelectedMeasurementPoints(settings)
    },
    async createAnalaysisSample(project) {
      try {
        return await this.$store
          .dispatch('setAnalysisSamples', {
            projectData: project
          })
      } catch (e) {
        console.log(e)
        return false
      }
    },
    setSelectedMeasurementPoints(settings) {
      const measurementpoints = this.parseTableToArray(this.project.VeldData.tblMeasurementPoints)
      const selectedMeasurementPoints = measurementpoints.filter(measurementPoint => this.selectedMeasurementPointIds.includes(measurementPoint.MpID))
      let allMeasurementPointSamples = []

      selectedMeasurementPoints.forEach(measurementPoint => {
        allMeasurementPointSamples = allMeasurementPointSamples
          .concat(this.getMeasurementPointSampleData(measurementPoint, settings.upperSoilDepth, settings.lowerSoilDepth))
      })
      this.selectedFormattedMeasurementPoints = allMeasurementPointSamples
    },
    getProjecWithOnlySelectedMeasurementpointsData() {
      const project = JSON.parse(JSON.stringify(this.project))
      const dataTableKeys = ['tblMeasurementPoints', 'tblLayers', 'tblJars']
      dataTableKeys.forEach(tableKey => {
        project.VeldData[tableKey] = project.VeldData[tableKey]
          .filter(tableItem => this.selectedMeasurementPointIds.includes(tableItem.MpID))
      })
      return project
    },
    getCodeDescriptionByCodeValue(codeListName, codeListOptionValue) {
      if (!codeListOptionValue) {
        return ''
      }

      if (!this.codeLists[codeListName]) {
        return ''
      }

      const description = this.codeLists[codeListName].find(
        (codeListItem) => codeListItem.GcCode === codeListOptionValue
      )

      if (!description) {
        return ''
      }

      return stringUtils.capitalizeFirstLetter(description.GcDescription)
    },
    recordIsError(record) {
      if (typeof record === 'number' && record.type === 1) return true
      if (typeof record === 'string' && record.type.toUpperCase() === 'ERROR') return true
      return false
    },
    formatErrorMessages(logRecords) {
      return logRecords.map(record => {
        if (!this.recordIsError(record)) {
          return record
        }
        let message = ''
        switch (record.context) {
          case 'SoilDepthTypeNotFoundError':
            const soilDepthType = Object.values(record.parameters).map(paramValue => this.formatSoilDepthType(paramValue))[0]
            message = `${this.$t('SuggestedSamples.NoSoilDepthTrajectoryFoundFor')}: ${soilDepthType.description}`
            break
          case 'SoilTypeNotFoundError':
            const soilTypeDescription = Object.values(record.parameters).map(paramValue => this.getCodeDescriptionByCodeValue('SoilType', paramValue))[0]
            message = `${this.$t('SuggestedSamples.NoSoilTypeFoundFor')}: ${soilTypeDescription}`
            break
          case 'AssignJarsWithSpecialTreatmentToOwnSuggestedSample':
            message = this.$t('SuggestedSamples.AllSamplesHaveAScoreOver100')
            break
          default:
            message = this.$t(`SuggestedSamples.${record.message}`)
            break
        }
        return { ...record, message }
      })
    },
    formatJarsFromResult(jars, upperSoilDepth, lowerSoilDepth, measurementPointsMemo) {
      const formattedSampleJars = []
      for (const jar of jars) {
        const formattedJar = this.formatSampleJar(jar, jars, upperSoilDepth, lowerSoilDepth, measurementPointsMemo)
        formattedSampleJars.push(formattedJar)
      }
      return formattedSampleJars
    },
    formatSuggestedAnalysisSamples(sugestedSamples, measurementPointsMemo) {
      const formattedSuggestSamples = []
      if (!sugestedSamples || sugestedSamples.length < 1) {
        return formattedSuggestSamples
      }

      for (const sample of sugestedSamples) {
        if (!sample.profile || !sample.profile.soilTypeCode) {
          continue
        }

        let jars = []
        if (sample.jars) {
          sample.jars.forEach(sampleJar => {
            const mpName = sampleJar.measurementObjectName
            const mpMemo = measurementPointsMemo[`${mpName}`]
            const layerIndex = mpMemo.layers.findIndex(layer => layer.guid === sampleJar.layerGUID)
            const jarIndex = mpMemo.layers[layerIndex].jars.findIndex(jar => jar.guid === sampleJar.guid)
            // Makes a copy of the data in mpMemo we do not want to alter
            // presentation contains only data that children will use
            const presentationLayers = JSON.parse(JSON.stringify(measurementPointsMemo[`${mpName}`].layers))
              .map(layer => {
                if (layer.guid === mpMemo.layers[layerIndex].guid) {
                  layer.selectedByUser = true
                  layer.hasBeenSuggested = true
                  // Soil depth type comes from selected jar
                  layer.soilDepthType = mpMemo.layers[layerIndex].jars[jarIndex].soilDepthType
                }

                return layer
              })
            const presentationJars = JSON.parse(JSON.stringify(mpMemo.layers[layerIndex].jars))
              .map((formattedJar, index) => this.parseFormattedJarToPresentationJar(formattedJar, index === jarIndex, index === jarIndex))
            const scoreSummaries = presentationLayers[layerIndex].jars[jarIndex].scoreSummaries
            const totalScore = presentationLayers[layerIndex].jars[jarIndex].totalScore
            presentationLayers[layerIndex].jars = presentationJars
            jars.push({
              scoreSummaries,
              totalScore,
              measurementPoint: { ...measurementPointsMemo[`${mpName}`], layers: presentationLayers }
            })
          })
        }
        formattedSuggestSamples.push({
          guid: sample.sampleGUID,
          number: sample.sampleNumber,
          score: sample.score,
          jars,
          profile: {
            soilDepthType: this.formatSoilDepthType(sample.profile.soilDepth),
            soilType: this.formatCode(sample.profile.soilTypeCode, 'SoilType'),
            subLocationName: this.getSublocationName(sample.profile.subLocationId),
            sublocationId: sample.profile.subLocationId
          },
          canCreate: true,
          analysisSampleName: ''
        })
      }

      return formattedSuggestSamples
    },
    getSublocationName(sublocationId) {
      if (!sublocationId || !this.project.VeldData.tblSublocations) {
        return '-'
      }
      const sublocations = this.parseTableToArray(this.project.VeldData.tblSublocations)
      const sublocation = sublocations.find(sublocation => sublocation.SlID === sublocationId)
      if (!sublocation || !sublocation.SlName) {
        return '-'
      }
      return sublocation.SlName
    },
    formatSoilDepthType(soilDepthCode) {
      const soilDepth = { code: soilDepthCode, description: this.$t('SuggestedSamples.Top') }
      switch (soilDepthCode) {
        case 'MidSoil':
          soilDepth.description = this.$t('SuggestedSamples.Mid')
          break
        case 'LowerSoil':
          soilDepth.description = this.$t('SuggestedSamples.Lower')
          break
      }
      return soilDepth
    },
    formatSampleJar(sampleJar, sampleJars, upperSoilDepth, lowerSoilDepth, measurementPointsMemo) {
      // uses memoization so save having to format the same measurement points, layers and jars again
      const mpName = sampleJar.measurementObjectName
      if (!measurementPointsMemo[`${mpName}`]) {
        measurementPointsMemo[`${mpName}`] = this.getFormattedMeasurementPoint(sampleJar, sampleJars, upperSoilDepth, lowerSoilDepth)
      }
      const formattedLayerIndexInMP = measurementPointsMemo[`${mpName}`].layers.findIndex(layer => layer.guid === sampleJar.layerGUID)
      return measurementPointsMemo[`${mpName}`].layers[formattedLayerIndexInMP].jars.find(jar => jar.guid === sampleJar.guid)
    },
    formatScoreSummaries(scoreSummaries, formattedSpecialCompounds, formattedPID, formattedOilAndWaterReaction, formattedOdour) {
      let hasSpecialCompound = false
      let hasPID = false
      let hasOilWaterReaction = false
      let hasOdour = false
      const reasons = scoreSummaries.sort((a, b) => b.score - a.score).map(scoreSummary => {
        switch (scoreSummary.scoreType) {
          case 1:
            const specialCompoundInLayer = formattedSpecialCompounds.find(specialCompound => specialCompound.type.code === scoreSummary.typeCode &&
              specialCompound.gradation.code === scoreSummary.gradientCode)
            hasSpecialCompound = true
            return `+${scoreSummary.score} ${specialCompoundInLayer.description}`
          case 2:
            hasPID = true
            return `+${scoreSummary.score} PID: ${formattedPID} (ppm)`
          case 3:
            hasOilWaterReaction = true
            return `+${scoreSummary.score} ${formattedOilAndWaterReaction.description}`
          case 4:
            hasOdour = true
            return `+${scoreSummary.score} Odour: ${formattedOdour.description}`
        }
      })

      return { hasOdour, hasOilWaterReaction, hasPID, hasSpecialCompound, reasons }
    },
    cloneJarsAndMarkSuggestedJar(formattedlayer, jarGuid) {
      const jarIndex = formattedlayer.jars.findIndex(jar => jar.guid === jarGuid)
      if (jarIndex === -1) {
        return []
      }
      // copy jars array values
      const jars = JSON.parse(JSON.stringify(formattedlayer.jars))
      jars[jarIndex].selectedByUser = true
      return jars
    },
    getFormattedMeasurementPoint(sampleJar, sampleJars, upperSoilDepth, lowerSoilDepth) {
      const allMeasurementPoints = this.parseTableToArray(this.project.VeldData.tblMeasurementPoints)
      const measurementPoint = allMeasurementPoints.find((mp) => mp.MpGuid === sampleJar.measurementObjectGUID)
      if (measurementPoint === undefined || this.project.VeldData.tblLayers === undefined) {
        return {}
      }

      const allLayers = this.parseTableToArray(this.project.VeldData.tblLayers)

      const allJars = this.parseTableToArray(this.project.VeldData.tblJars)

      const jarsInMP = allJars.filter((jar) => jar.MpID === measurementPoint.MpID)
      const layersInMP = allLayers.filter((layer) => layer.MpID === measurementPoint.MpID)

      const formattedLayers = layersInMP.reduce((formattedLayersArray, layer) => {
        const sampleJarForLayer = sampleJars.find(jar => jar.layerGUID === layer.LaGuid)
        const formattedLayer = this.formatLayer(layer, jarsInMP, sampleJarForLayer, upperSoilDepth, lowerSoilDepth)
        if (formattedLayer) {
          formattedLayersArray.push(formattedLayer)
        }

        return formattedLayersArray
      }, []).sort((a, b) => a.from - b.from)

      return {
        id: parseInt(measurementPoint.MpID),
        name: measurementPoint.MpName,
        guid: measurementPoint.MpGuid,
        layers: formattedLayers
      }
    },
    getMeasurementPointSampleData(measurementPoint, upperSoilDepth, lowerSoilDepth) {
      const allJars = this.parseTableToArray(this.project.VeldData.tblJars)
      const jarsInMeasurementPoint = allJars.filter((jar) => jar.MpID === measurementPoint.MpID)

      const allLayers = this.parseTableToArray(this.project.VeldData.tblLayers)
      const layersInMeasurementPoint = allLayers.filter((layer) => layer.MpID === measurementPoint.MpID)

      const formattedLayers = []
      layersInMeasurementPoint.forEach(layer => {
        formattedLayers.push(this.formatLayer(layer, jarsInMeasurementPoint, {}, upperSoilDepth, lowerSoilDepth))
      })
      return {
        measurementPoint: {
          name: measurementPoint.MpName,
          guid: measurementPoint.MpGuid,
          id: measurementPoint.MpID,
          layers: formattedLayers,
          sublocationId: measurementPoint.SlID
        }
      }
    },
    initMeasurementPointFeatures() {
      // If a single measurement is returned comes back as an object not array
      const measurementpoints = this.parseTableToArray(this.project.VeldData.tblMeasurementPoints)
      this.measurementPointFeatures = measurementpoints.reduce((mpFeaturesArray, mp) => {
        if (!mp.MpPointGeometry) {
          return mpFeaturesArray
        }
        const geometryString = mp.MpPointGeometry.substring(
          mp.MpPointGeometry.indexOf('(') + 1,
          mp.MpPointGeometry.lastIndexOf(')')
        )
        const geometryArray = geometryString.split(' ')
        if (geometryArray.length !== 2) {
          return mpFeaturesArray
        }
        const X = parseFloat(geometryArray[0])
        const Y = parseFloat(geometryArray[1])
        if (X <= 0 || Y <= 0) {
          return mpFeaturesArray
        }
        mpFeaturesArray.push({
          Guid: mp.MpGuid,
          Id: mp.MpID,
          X,
          Y,
          TypeCode: mp.MpTypeCode,
          Name: mp.MpName
        })
        return mpFeaturesArray
      }, [])
    },
    formatLayer(layer, jars, sampleJar, upperSoilDepth, lowerSoilDepth) {
      const from = parseInt(layer.LaFrom)
      const to = parseInt(layer.LaTo)
      const specialCompounds = this.getLayerSpecialCompounds(layer)
      const pid = layer.LaPID && layer.LaPID !== '0' ? parseInt(layer.LaPID) : 0
      const oilWaterReaction = this.getLayerOilWaterReaction(layer)
      const odour = this.getLayerOdour(layer)
      const scoreSummaries = sampleJar && sampleJar.scoreSummaries && sampleJar.scoreSummaries.length > 0 ? this.formatScoreSummaries(sampleJar.scoreSummaries, specialCompounds, pid, oilWaterReaction, odour) : []
      const totalScore = sampleJar && sampleJar.totalScore ? sampleJar.totalScore : 0
      const id = parseInt(layer.LaID)
      const guid = layer.LaGuid
      const remark = layer.LaRemark ? layer.LaRemark : ''
      const soilType = this.formatCode(layer.LaSoilTypeCode, 'SoilType', '-')
      const additives = this.getLayerSoilTypeAndAdditives(layer)
      const jarsInLayer = this.getJarsInLayer(jars, from, to)
        .map(jar => this.formatJar(jar, totalScore, scoreSummaries, upperSoilDepth, lowerSoilDepth))
        .sort(this.sortFormattedJarsFunc)

      return {
        id,
        guid,
        to,
        from,
        remark,
        oilWaterReaction,
        odour,
        pid,
        specialCompounds,
        soilType,
        additives,
        jars: jarsInLayer,
        selectedByUser: false,
        hasBeenSuggested: false,
        soilDepthType: jarsInLayer.length > 0 ? jarsInLayer[0].soilDepthType : this.getSoilDepthType(from, to, upperSoilDepth, lowerSoilDepth)
      }
    },
    getSoilDepthType(from, to, upperSoilDepth, lowerSoilDepth) {
      const depthMean = (from + to) / 2
      if (depthMean <= upperSoilDepth) {
        return { code: 'TopSoil', description: this.$t('SuggestedSamples.Topsoil') }
      } else if (depthMean <= lowerSoilDepth) {
        return { code: 'MidSoil', description: this.$t('SuggestedSamples.Midsoil') }
      } else {
        return { code: 'LowerSoil', description: this.$t('SuggestedSamples.Lowersoil') }
      }
    },
    formatJar(jar, totalScore, scoreSummaries, upperSoilDepth, lowerSoilDepth) {
      if (!jar) {
        return
      }
      const from = parseInt(jar.JrFrom)
      const to = parseInt(jar.JrTo)
      const id = parseInt(jar.JrID)
      const guid = jar.JrGuid
      const name = jar.JrName
      const remark = jar.JrRemark ? jar.JrRemark : ''
      const barcode = jar.JrBarcode ? jar.JrBarcode : '-'
      const type = this.formatCode(jar.JrPackingTypeCode, 'JarType')
      const creationDate = moment(jar.JrDateTime).format('YYYY-MM-DDTHH:mm:ss')
      const soilDepthType = this.getSoilDepthType(from, to, upperSoilDepth, lowerSoilDepth)
      return {
        from,
        to,
        id,
        guid,
        name,
        remark,
        barcode,
        type,
        creationDate,
        selectedByUser: false,
        hasBeenSuggested: false,
        scoreSummaries,
        totalScore,
        soilDepthType
      }
    },
    getJarsInLayer(jars, from, to) {
      const jarsInLayer = []
      for (let i = 0; i < jars.length; i++) {
        const jarCenter = (parseInt(jars[i].JrFrom) + parseInt(jars[i].JrTo)) / 2
        if (from > jarCenter) {
          break
        }
        if (from < jarCenter && to >= jarCenter) {
          jarsInLayer.push(jars[i])
          jars.splice(i, 1)
          i--
        }
      }
      return jarsInLayer
    },
    getLayerSoilTypeAndAdditives(layer) {
      if (!layer) {
        return []
      }
      const soilAndAdditives = []
      for (let i = 1; i < 7; i++) {
        if (
          layer[`LaAd${i}TypeCode`] === undefined ||
          layer[`LaAd${i}GradationCode`] === undefined
        ) {
          break
        } else {
          const soilType = this.formatCode(layer[`LaAd${i}TypeCode`], 'SoilAdditive')

          const gradation = this.formatCode(layer[`LaAd${i}GradationCode`], 'AdditiveGradiation')

          const description = gradation.description === ''
            ? soilType.description
            : `${soilType.description} (${gradation.description.toLowerCase()})`

          soilAndAdditives.push({ soilType, gradation, description })
        }
      }
      return soilAndAdditives
    },
    formatCode(code, codeListName, notFoundValue = '') {
      const formattedCodeDescription = { code: '', description: '' }
      if (!code) {
        formattedCodeDescription.description = notFoundValue
        return formattedCodeDescription
      }
      formattedCodeDescription.code = code
      formattedCodeDescription.description = this.getCodeDescriptionByCodeValue(
        codeListName,
        code
      )
      if (!formattedCodeDescription.description) {
        formattedCodeDescription.description = notFoundValue
      }
      return formattedCodeDescription
    },
    getLayerOdour(layer) {
      if (!layer) {
        return []
      }
      const odour = { type: { code: '', description: '' }, intensity: { code: '', description: '' }, description: '' }
      if (layer.LaOdourTypeCode === undefined || layer.LaOdourIntensityCode === '0') {
        return odour
      }

      odour.type = this.formatCode(layer[`LaOdourTypeCode`], 'OdourType')

      odour.intensity = this.formatCode(layer[`LaOdourIntensityCode`], 'OdourIntensity')

      odour.description = `${odour.type.description} (${odour.intensity.description.toLowerCase()})`

      return odour
    },
    getLayerOilWaterReaction(layer) {
      if (layer.LaOWTypeCode && layer.LaOWTypeCode === '0') {
        return { code: '', description: '' }
      }
      return this.formatCode(layer.LaOWTypeCode, 'OilWaterReaction')
    },
    getLayerSpecialCompounds(layer) {
      if (!layer) {
        return []
      }
      const specialCompounds = []
      for (let i = 1; i < 7; i++) {
        if (layer[`LaSc${i}TypeCode`] === undefined) {
          break
        } else {
          const gradation = this.formatCode(layer[`LaSc${i}GradationCode`], 'SpecialCompoundGradiation')

          const type = this.formatCode(layer[`LaSc${i}TypeCode`], 'SpecialCompound')

          const description = type.description === ''
            ? type.description
            : `${type.description} (${gradation.description.toLowerCase()})`

          specialCompounds.push({ type, gradation, description })
        }
      }
      return specialCompounds
    },
    parseTableToArray(table) {
      if (!table) {
        return []
      }
      let tableData = table
      if (!Array.isArray(tableData)) {
        return [tableData]
      }
      return tableData
    },
    sortFormattedJarsFunc(a, b) { return ((a.from + a.to) / 2) - ((b.from + b.to) / 2) },
    startCreatingSample(sample) {
      this.results.formattedSuggestedSamples[sample.number - 1].canCreate = false
      this.newAnalysisSample.suggestedSampleNumber = sample.number
      this.newAnalysisSample.jars = sample.jars
      this.newAnalysisSample.sampleName = this.getDefaultName(sample)
      this.showCreateSampleModal = true
    },
    cancelCreatingSample() {
      this.showCreateSampleModal = false
      this.resetNewAnalysisSample(false)
      this.results.formattedSuggestedSamples[this.newAnalysisSample.suggestedSampleNumber - 1].canCreate = true
    },
    async completeCreatingSample() {
      this.showCreateSampleModal = false
      this.loading = true
      const newAnalysisSampleObject = this.generateNewAnalaysisSampleObject()
      const projectWithNewAnalysisSample = this.getProjectWithNewAnalysisSample(newAnalysisSampleObject)
      const sampleSuccessfulCreated = await this.createAnalaysisSample(projectWithNewAnalysisSample)
      this.loading = false
      if (sampleSuccessfulCreated) {
        this.$store.dispatch('LogFrontEndActivity', { activityType: '314', activityMessage: `Created a analysis sample from sample suggesting for project: ${this.parameterProjectId}` })
        this.previouslyCreatedAnalysisSamples.push(newAnalysisSampleObject)
        this.results.formattedSuggestedSamples[this.newAnalysisSample.suggestedSampleNumber - 1].analysisSampleName = this.newAnalysisSample.sampleName
        this.isLatestProjectData = false
        cookie.set('suggest_samples_created_new_sample', true)
      } else {
        this.results.formattedSuggestedSamples[this.newAnalysisSample.suggestedSampleNumber - 1].canCreate = true
        TiPopup.popup(this.$t('SuggestedSamples.ErrorCreatingSample'))
      }
      this.resetNewAnalysisSample(sampleSuccessfulCreated)
    },
    creatingSampleNameChange(name) {
      this.newAnalysisSample.sampleName = name
    },
    creatingSampleMatrixCodeChange(code) {
      this.newAnalysisSample.matrixCode = code
    },
    getProjectWithNewAnalysisSample(newAnalysisSample) {
      const analaysisSampleTable = this.getAnalysisSamplesFromProjectData(false)
      const samplePartsTable = this.parseTableToArray(this.project.VeldData.tblSampleParts)
      // In order to prevent adding new sample directly to analysisSampleTable create a clone
      const samplePartsTableClone = JSON.parse(JSON.stringify(samplePartsTable))
      const analysisSampleTableClone = JSON.parse(JSON.stringify(analaysisSampleTable))
      analysisSampleTableClone.push(newAnalysisSample)
      this.newAnalysisSample.jars.forEach(jar => {
        samplePartsTableClone.push({
          AnID: newAnalysisSample.AnID,
          JrID: jar.id.toString()
        })
      })
      return {
        ...this.project,
        VeldData: {
          ...this.project.VeldData,
          tblAnalysisSamples: analysisSampleTableClone,
          tblSampleParts: samplePartsTableClone
        }
      }
    },
    generateNewAnalaysisSampleObject() {
      return {
        AnID: this.generateNewAnalysisSampleId(),
        AnName: this.newAnalysisSample.sampleName,
        AnFrom: Math.min(...this.newAnalysisSample.jars.map(jar => jar.from)),
        AnTo: Math.max(...this.newAnalysisSample.jars.map(jar => jar.to)),
        AnSampleTaker: '',
        AnMatrix: this.newAnalysisSample.matrixCode,
        AnDateLastChanged: moment().format('YYYY-MM-DDTHH:mm:ss'),
        AnGuid: guidUtils.newGuid(),
        AnDatum: this.newAnalysisSample.jars.sort((a, b) => Date.parse(a) - Date.parse(b))[0].creationDate
      }
    },
    resetNewAnalysisSample(sampleSuccessfullyCreated = false) {
      this.newAnalysisSample.matrixCode = '1'
      if (sampleSuccessfullyCreated && this.newAnalysisSample.jars.length > 1) {
        this.newAnalysisSample.previousUserInputtedName = this.newAnalysisSample.sampleName
      }
      this.newAnalysisSample.jars = []
    },
    generateNewAnalysisSampleId() {
      const analaysisSampleTable = this.getAnalysisSamplesFromProjectData()
      if (analaysisSampleTable.length === 0) {
        return '1'
      }
      return (Math.max(...analaysisSampleTable.map(sample => Number(sample.AnID))) + 1).toString()
    },
    getDefaultName(sample) {
      if (sample.jars.length > 1 && !this.newAnalysisSample.previousUserInputtedName) {
        return ''
      }
      let proposedName = ''
      if (sample.jars.length === 1) {
        proposedName = sample.jars[0].mpName + '-' + sample.jars[0].name
      } else {
        proposedName = this.newAnalysisSample.previousUserInputtedName
      }
      if (!this.analysisSampleNameExists(proposedName)) {
        return proposedName
      }
      if (sample.jars.length === 1 && proposedName.match(/[0-9]+$/)) {
        // if mp1-jar1 is taken want mp1-jar1A
        return this.getNextIncreamentedAnalysisSampleNameByLetter(proposedName)
      }
      return this.getNextIncreamentedAnalysisSampleNameByNumber(proposedName)
    },
    switchJar({ sampleNumber, sampleJarIndex, layerIndex, jarIndex }) {
      this.showSwitchJarModal = true
      this.jarToSwitch.sampleIndex = sampleNumber - 1
      this.jarToSwitch.sampleJarIndex = sampleJarIndex
      this.jarToSwitch.newSelectedLayerIndex = layerIndex
      this.jarToSwitch.newSelectedJarIndex = jarIndex
    },
    // Takes a sample jar from the formattedSampleJar list which holds all related score, soil depth, total score data
    // parses the sampleJar data to the format that will be presented to the user.
    // Vue set is needed to trigger reactivity
    confirmJarSwitch() {
      const suggestedSampleJars = this.results.formattedSuggestedSamples[this.jarToSwitch.sampleIndex].jars
      const measurementPoint = suggestedSampleJars[this.jarToSwitch.sampleJarIndex].measurementPoint

      const currentSelectedLayerIndex = measurementPoint.layers.findIndex(layer => layer.selectedByUser)
      const currentSelectedLayer = measurementPoint.layers[currentSelectedLayerIndex]

      const currentSelectedJarIndex = currentSelectedLayer.jars.findIndex(jar => jar.selectedByUser)
      const currentSelectedJar = currentSelectedLayer.jars[currentSelectedJarIndex]

      const newSelectedLayer = measurementPoint.layers[this.jarToSwitch.newSelectedLayerIndex]
      const newSelectedJar = newSelectedLayer.jars[this.jarToSwitch.newSelectedJarIndex]

      const formattedCurrentJar = this.results.formattedSampleJars.find(formattedJar => currentSelectedJar.guid === formattedJar.guid)
      const presentationCurrentJar = this.parseFormattedJarToPresentationJar(formattedCurrentJar, false, currentSelectedJar.hasBeenSuggested)

      const formattedNewJar = this.results.formattedSampleJars.find(formattedJar => newSelectedJar.guid === formattedJar.guid)
      const presentationNewJar = this.parseFormattedJarToPresentationJar(formattedNewJar, true, newSelectedJar.hasBeenSuggested)
      Vue.set(currentSelectedLayer.jars, currentSelectedJarIndex, presentationCurrentJar)
      Vue.set(newSelectedLayer.jars, this.jarToSwitch.newSelectedJarIndex, presentationNewJar)

      if (currentSelectedLayer.guid !== newSelectedLayer.guid) {
        // Soil depth depends on selected jar
        Vue.set(measurementPoint.layers, this.jarToSwitch.newSelectedLayerIndex, {
          ...newSelectedLayer,
          selectedByUser: true,
          soilDepthType: formattedNewJar.soilDepthType
        })
      }
      Vue.set(measurementPoint.layers, currentSelectedLayerIndex, {
        ...currentSelectedLayer,
        selectedByUser: currentSelectedLayer.guid === newSelectedLayer.guid,
        soilDepthType: formattedCurrentJar.soilDepthType
      })

      Vue.set(suggestedSampleJars, this.jarToSwitch.sampleJarIndex, {
        measurementPoint,
        totalScore: formattedNewJar.totalScore,
        scoreSummaries: formattedNewJar.scoreSummaries
      })
      this.resetJarToSwitch()
    },
    cancelJarSwitch() {
      this.resetJarToSwitch()
      this.showSwitchJarModal = false
    },
    resetJarToSwitch() {
      this.jarToSwitch.newSelectedLayerIndex = -1
      this.jarToSwitch.newSelectedJarIndex = -1
      this.jarToSwitch.sampleIndex = -1
      this.jarToSwitch.sampleJarIndex = -1
    },
    getNextIncreamentedAnalysisSampleNameByNumber(proposedName) {
      const proposedNameLower = this.removeTrailingNumbersExceptZeros(proposedName).toLowerCase()
      let trailingProposedNumber = Number(proposedName.match(/[0-9]+$/)) // If match fails returns 0
      const originalDigitCount = trailingProposedNumber.toString().length
      const trailingNumbersOrdered = this.getAnalysisSamplesFromProjectData().filter(sample => {
        const sampleName = sample.AnName.toLowerCase()
        if (proposedNameLower.length > sampleName.length) {
          return false
        }
        if (this.removeTrailingNumbersExceptZeros(sampleName).toLowerCase() !== proposedNameLower) {
          return false
        }
        if (trailingProposedNumber > Number(sampleName.match(/[0-9]+$/))) {
          return false
        }
        return true
      }).map(sample => Number(sample.AnName.match(/[0-9]+$/)))
        .sort((a, b) => sortUtils.naturalSort(a, b))
      for (let i = 0; i < trailingNumbersOrdered.length; i++) {
        // Do not want Mp09 to be Mp010
        if (proposedNameLower.charAt(proposedNameLower.length - 1) === '0' && trailingProposedNumber.toString().length > originalDigitCount) {
          break
        }
        if (i === 0 && trailingProposedNumber < trailingNumbersOrdered[i]) {
          // is least number
          break
        }
        if (i > 0 && trailingProposedNumber < trailingNumbersOrdered[i] &&
          trailingProposedNumber > trailingNumbersOrdered[i - 1]) {
          // there is a number higher and lower
          break
        }
        if (i + 1 === trailingNumbersOrdered.length) {
          if ((i > 1 && trailingProposedNumber > trailingNumbersOrdered[i - 1]) &&
            trailingProposedNumber < trailingNumbersOrdered[i]) {
            // last number higher than needed next number is 6 and last number 8
            break
          }
          trailingProposedNumber = trailingNumbersOrdered[i] + 1
          break
        }

        if (trailingProposedNumber > trailingNumbersOrdered[i] &&
          trailingProposedNumber < trailingNumbersOrdered[i + 1]) {
          // number is between higher and lower current number 6 next number 7 and previous number 5
          break
        }

        trailingProposedNumber++
      }
      if (proposedNameLower.charAt(proposedNameLower.length - 1) === '0' && trailingProposedNumber.toString().length > originalDigitCount) {
        const nameNoTrailingNumbers = proposedName.replace(/\d+$/, '')
        const zeroCount = this.getTrailingZeroStringCountMinus1(this.removeTrailingNumbersExceptZeros(proposedName))
        const zerosStringMinus1Zero = '0'.repeat(zeroCount)
        // Mp09 becomes Mp10
        return this.getNextIncreamentedAnalysisSampleNameByNumber(`${nameNoTrailingNumbers}${zerosStringMinus1Zero}${trailingProposedNumber}`)
      }
      return `${this.removeTrailingNumbersExceptZeros(proposedName)}${trailingProposedNumber}`
    },
    getNextIncreamentedAnalysisSampleNameByLetter(proposedName) {
      const proposedNameLower = proposedName.toLowerCase()
      const names = this.getAnalysisSamplesFromProjectData().filter(sample => {
        const sampleName = sample.AnName.toLowerCase()
        if (proposedNameLower.length > sampleName.length || (proposedNameLower.length + 1) < sampleName.length) {
          return false
        }
        if (sampleName.substring(0, proposedNameLower.length) !== proposedNameLower) {
          return false
        }
        if (!sampleName.charAt(sampleName.length - 1).match(/[a-z]/)) {
          return false
        }
        return true
      })
      if (names.length === 1 && names[0] === proposedName) {
        return `${proposedName}a`
      }
      const orderedLetters = names.map(name => name.AnName.charAt(name.length - 1)).sort((a, b) => sortUtils.naturalSort(a, b))
      let letterAtEndOfProposedName = 'a'
      let letterToNumCount = 1
      let letterAtEndOfCurrentName = orderedLetters[0]
      for (let i = 0; i < orderedLetters.length; i++) {
        if (i + 1 === orderedLetters.length && letterAtEndOfProposedName < letterAtEndOfCurrentName) {
          break
        }
        const nextLetterAtEndOfSimilairName = orderedLetters[i + 1]
        if (letterAtEndOfProposedName > letterAtEndOfCurrentName && letterAtEndOfProposedName < nextLetterAtEndOfSimilairName) {
          break
        }
        letterToNumCount++
        if (letterToNumCount > 26) {
          const completedName = `${proposedName}${this.numberToLetter(letterToNumCount).toUpperCase()}`
          while (this.analysisSampleNameExists(completedName)) {
            letterToNumCount++
          }
          return completedName
        }
        letterAtEndOfProposedName = this.numberToLetter(letterToNumCount)
        letterAtEndOfCurrentName = nextLetterAtEndOfSimilairName
      }
      return `${proposedName}${letterAtEndOfProposedName.toUpperCase()}`
    },
    getAnalysisSamplesFromProjectData(includeAlreadyCreatedSamples = true) {
      const analysisSamples = this.parseTableToArray(this.project.VeldData.tblAnalysisSamples)
      if (includeAlreadyCreatedSamples) {
        return analysisSamples.concat(this.previouslyCreatedAnalysisSamples)
      }
      return analysisSamples
    },
    numberToLetter(num) {
      for (var ret = '', a = 1, b = 26; (num -= a) >= 0; a = b, b *= 26) {
        ret = String.fromCharCode(parseInt((num % b) / a, 10) + 65) + ret
      }
      return ret
    },
    analysisSampleNameExists(name) {
      const nameLowerCase = name.toLowerCase()
      return this.getAnalysisSamplesFromProjectData()
        .findIndex(sample => sample.AnName.toLowerCase() === nameLowerCase) !== -1
    },
    removeTrailingNumbersExceptZeros(name) {
      let nameNoTrailingNumbers = name.replace(/\d+$/, '')
      const trailingNumbersString = name.split(/(\d+$)/g)[1]
      if (!trailingNumbersString || trailingNumbersString === '0') {
        return nameNoTrailingNumbers
      }

      return `${nameNoTrailingNumbers}${'0'.repeat((trailingNumbersString.match(/^0+/) || [''])[0].length)}`
    },
    getTrailingZeroStringCountMinus1(name) {
      let zeroCount = ((name.split(/(\d+$)/g)[1]).match(/^0+/) || [''])[0].length
      if (zeroCount > 0) {
        zeroCount--
      }
      return zeroCount
    },
    parseFormattedJarToPresentationJar(formattedJar, selectedByUser = false, hasBeenSuggested = false) {
      return {
        barcode: formattedJar.barcode,
        creationDate: formattedJar.creationDate,
        from: formattedJar.from,
        to: formattedJar.to,
        guid: formattedJar.guid,
        id: formattedJar.id,
        name: formattedJar.name,
        remark: formattedJar.remark,
        type: formattedJar.type,
        selectedByUser,
        hasBeenSuggested
      }
    }
  }
}
</script>
<style>
.suggest-analysis-content-container {
  max-width: 1800px;
  margin-left: auto !important;
  margin-right: auto !important;
  overflow-x: hidden;
}

.suggested-samples-input-output-column {
  height: 550px;
  margin-bottom: 20px;
  padding: 0px;
}

.suggested-samples-input-output-column .panel {
  height: 100%;
  padding: 0px;
}

.suggested-samples-input-output-column .control-label {
  text-align: left !important;
  padding: 7px 0px 0px 0px;
}

.suggested-samples-input-output-column .form-group {
  margin-bottom: 0px;
  vertical-align: bottom;
  white-space: nowrap;
}

.input-column {
  min-width: 475px;
  margin-left: 4%;
}

.suggested-sample-column {
  margin-left: 4%;
  padding: 0px;
  min-width: 1220px;
  overflow-x: auto;
}

.suggested-sample-column .panel {
  min-width: 1220px;
}

.modal-container .backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1001;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-container .modal-dialog {
  width: 370px;
}

.modal-container .modal-footer {
  display: flex;
  justify-content: space-between;
}
.unauthorized {
  margin-top: 20px;
}
</style>
