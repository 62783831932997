<template>
  <transition name="fade">
    <div v-show="showModal" class="backdrop">
      <div class="terraIndexModal">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                @click="closeModal">
                &times;
              </button>
              <h4 class="modal-title">{{$t('SuggestedSamples.CreateAnalysisSample')}}</h4>
            </div>
            <div class="modal-body">
              <div class="input-container">
                <label for="new-analysis-sample-name">
                  {{$t('SuggestedSamples.CreateAnalysisSampleName')}}
                </label>
                <input id="new-analysis-sample-name"
                      v-model="sampleName"
                      :class="errorMessage === ''? '':'invalid'"
                      class="form-control"/>
                <p class="error-message"> {{errorMessage}}</p>
              </div>
              <div class="input-container">
                <label for="new-analysis-sample-select-matrix-type">
                  {{$t('SuggestedSamples.CreateAnalysisSampleMatrix')}}
                </label>
                <select
                  id="new-analysis-sample-select-matrix-type"
                  v-model="sampleMatrixCode"
                  class="form-control">
                  <option :value="'1'">{{ $t('SuggestedSamples.MatrixGround') }}</option>
                  <option :value="'5'">{{ $t('SuggestedSamples.MatrixWaterBed') }}</option>
                  <option :value="'6'">{{ $t('SuggestedSamples.MatrixOther') }}</option>
                </select>
              </div>
            </div>
            <div class="modal-footer">
              <button
                class="btn"
                @click="closeModal">
                {{$t('other.close')}}
              </button>
              <button
                class="btn"
                :disabled="errorMessage !== ''"
                @click="createSample">
              {{$t('SuggestedSamples.CreateAnalysisSample')}}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>

export default {
  name: 'CreateAnalysisSampleModal',
  props: {
    defaultName: {
      type: String,
      required: false
    },
    matrixCode: {
      type: String,
      required: true
    },
    show: {
      type: Boolean,
      required: true
    },
    sampleNameTaken: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    closeModal() {
      this.$emit('cancelCreatingSample')
    },
    createSample() {
      this.$emit('completeCreatingSample')
    }
  },
  computed: {
    sampleName: {
      get() {
        return this.defaultName
      },
      set(value) {
        this.$emit('update:sampleName', value)
      }
    },
    sampleMatrixCode: {
      get() {
        return this.matrixCode
      },
      set(value) {
        this.$emit('update:sampleMatrixCode', value)
      }
    },
    showModal: {
      get() {
        return this.show
      }
    },
    errorMessage() {
      if (this.sampleNameTaken) {
        return this.$t('message.nameShouldUnique')
      }
      if (this.defaultName === '') {
        return this.$t('message.valueRequiredByUser')
      }
      return ''
    }
  }
}
</script>

<style scoped >
  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .2s;
  }
  .fade-enter, .fade-leave-to{
    opacity: 0;
  }
  .error-message {
    color: #dc3545
  }
  .invalid {
    border-color: #dc3545;
  }
  .input-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%;
  }
  .input-container p, label{
    align-self: flex-start;
  }

  .input-container input {
    width: 100%;
  }
  .input-container select {
    width: 100%;
  }

</style>
