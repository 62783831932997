<template>
  <div
    :class=" { 'jars-size': showJars, 'background-enabled': includeInSample }"
    class="row sample-jar-container">
      <div class="col-sm-12 header-container" >
        <div class="title-container">
         <h2
         :style="{
            fontSize: getTitleFontSize(sampleJar.measurementPoint.name)
          }"
          class="title">
          {{sampleJar.measurementPoint.name}}
        </h2>
         <div v-if="showCheckbox" class="checkbox-container">
          <input
            :id="checkboxId"
            v-model="includeInSample"
            class="form-check-input"
            type="checkbox"
            :disabled="excludeCheckboxIsReadonly"
            @click="excludedJarChanged"/>
         </div>
        </div>
        <div v-if="showScore" class="score-text">
          <h4>{{$t('SuggestedSamples.Score')}}: {{sampleJar.totalScore}}</h4>
        </div>
      </div>
      <div v-if="showReasons">
        <div class="col-sm-12 reason-labels-container">
          <div v-for="reason in reasons"  :key="reason.key" :class="reason.class">
            <label>{{reason.label}}</label>
          </div>
        </div>
        <div class="col-sm-12 reasons-description-container" >
          <p  v-for="(reason, index) in sampleJar.scoreSummaries.reasons"
              :key="generateReasonKey(index)">
          {{reason}}
          </p>
        </div>
      </div>
      <div class="col-sm-12 boxes-container" >
        <SuggestAnalysisSampleBoxes
          :measurementPoint="sampleJar.measurementPoint"
          :sampleNumber="sampleNumber"
          :canSwitchJars="!excludeCheckboxIsReadonly && includeInSample"
          :showJars="showJars"
          @switchJar="switchJar"/>
      </div>
  </div>
</template>

<script>
import SuggestAnalysisSampleBoxes from './SuggestAnalysisSampleBoxes.vue'
export default {
  name: 'SuggestAnalysisSampleJarCard',
  components: {
    SuggestAnalysisSampleBoxes
  },
  props: {
    sampleJar: {
      type: Object,
      required: true
    },
    sampleNumber: {
      type: Number,
      required: true
    },
    excludeCheckboxIsReadonly: {
      type: Boolean,
      required: false
    },
    sampleJarIndex: {
      type: Number,
      required: true
    },
    showReasons: {
      type: Boolean,
      default: true
    },
    showScore: {
      type: Boolean,
      default: true
    },
    showJars: {
      type: Boolean,
      default: true
    },
    showCheckbox: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      includeInSample: true
    }
  },
  methods: {
    generateReasonKey(index) {
      return `reason_${this.sampleNumber}_${this.sampleJar.measurementPoint.name}_${index}`
    },
    excludedJarChanged(e) {
      if (this.excludeCheckboxIsReadonly) {
        e.preventDefault()
        return
      }
      this.$emit('excludedJarChanged', this.sampleJar.measurementPoint.guid)
    },
    switchJar(sampleJar) {
      this.$emit('switchJar', {
        sampleJarIndex: this.sampleJarIndex,
        layerIndex: sampleJar.layerIndex,
        jarIndex: sampleJar.jarIndex
      }
      )
    },
    getTitleFontSize(title) {
      if (title.length > 38) {
        return '6px'
      }
      if (title.length > 35) {
        return '7px'
      }
      if (title.length > 27) {
        return '9px'
      }
      if (title.length > 25) {
        return '10px'
      }
      if (title.length > 23) {
        return '11px'
      }
      if (title.length > 20) {
        return '13px'
      }
      if (title.length > 18) {
        return '14px'
      }
      if (title.length > 17) {
        return '15px'
      }
      if (title.length > 15) {
        return '16px'
      }
      if (title.length > 14) {
        return '17px'
      }
      if (title.length > 13) {
        return '19px'
      }
      if (title.length > 12) {
        return '21px'
      }
      if (title.length > 11) {
        return '22px'
      }
      if (title.length > 9) {
        return '25px'
      }
      return '30px'
    }
  },
  computed: {
    reasons() {
      const base = 'field-name reason-label-box'
      const has = 'reason-label-box-has'
      const not = 'reason-label-box-not-in '
      const key = `${this.sampleJar.measurementPoint.name}_${this.sammpleNumber}`
      return [
        { key: `${key}_reasonLabelPID`,
          label: `${this.$t('SuggestedSamples.PID')}`,
          class: `${base} ${this.sampleJar.scoreSummaries.hasPID ? has : not}` },
        { key: `${key}_reasonLabelOdour`,
          label: `${this.$t('SuggestedSamples.Odour')}`,
          class: `${base} ${this.sampleJar.scoreSummaries.hasOdour ? has : not}` },
        { key: `${key}_reasonLabelOW`,
          label: `${this.$t('SuggestedSamples.OW')}`,
          class: `${base} ${this.sampleJar.scoreSummaries.hasOilWaterReaction ? has : not}` },
        { key: `${key}_reasonLabelSC`,
          label: `${this.$t('SuggestedSamples.SC')}`,
          class: `${base} ${this.sampleJar.scoreSummaries.hasSpecialCompound ? has : not}` }
      ]
    },
    checkboxId() {
      return `sample_${this.sampleNumber}_${this.sampleJar.measurementPoint.name}_checkbox`
    },
    backgroundClass() {
      return this.includeInSample ? 'background-enabled' : 'background-disabled'
    }
  }
}
</script>
<style scoped >
.sample-jar-container {
  min-height: 400px;
  width: 250px;
  box-shadow: 0 4px 10px rgb(0 0 0 / 7%);
  padding: 15px;
  transition: all .5s cubic-bezier(.36,.03,.28,.99);
  border-radius: 10px;
  font-family: Roboto,sans-serif;
  -webkit-font-smoothing: antialiased;
  padding-top: 0px;
}
.jars-size {
  min-width: 250px;
}
.background-enabled {
  background-color: #fff;
  border: 2px solid #dbd9d7;
}
.header-container {
  text-align: start;
  vertical-align: middle;
  display: flex;
  flex-direction: column;
  padding: 2px;
}
.reason-labels-container  {
  display: flex;
  justify-content: space-evenly;
  padding: 0px
}
.reason-labels-container label  {
  margin-bottom: 0px;
  vertical-align: middle;
}
.reason-label-box {
  flex: 1 1 0px;
  background-color: #DBD9D7;
  text-align: center;
  margin: 2px;
  min-width: 0px;
  padding: 7px 0 7px 0;
}

.reason-label-box-not-in {
  border: solid 3px #CDC8C3;
}

.reason-label-box-has {
  border: solid 3px #D32323;
  background-color: #EB5757;
}
.reasons-description-container {
  margin: 10px 0px 10px 0px;
  background-color: #e4e4e4;
  overflow-y: scroll;
  height: 100px;
  border-radius: 3px;
  padding: 10px 1px 2px 10px;
}
.reasons-description-container p {
  padding: 1px;
  font-family: Roboto,sans-serif;
  color: #876f5e;
  margin-bottom: 2px;
}
.title {
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: clip;
  margin-bottom: 3px;
  height: 33px;
}
.score-text {
  display: inline-flex;
  align-items: baseline;
  font-family: Roboto,sans-serif;
}

.score-text p {
  margin-left: 5px;
}

.boxes-container {
  margin: 0;
  padding: 0;
}

.title-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.checkbox-container {
  display: flex;
  flex-direction: column;
  width: 8px;
  position: relative;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 4px;
  margin-left: 5px;
}

.checkbox-container input{
  border-radius: .25em;
  height: 24px;
  width: 24px;
  vertical-align: top;
  background-color: #f9f9f9;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
  border: 1px solid rgba(0,0,0,.25);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  position: absolute;
  bottom: 27px;
  right: -14px;

}
.checkbox-container input:checked {
  background: #89b368;
  background-image: url('../../assets/svg/icon-check.svg');
  background-repeat: no-repeat;
  background-size: 14px;
  background-position: 6px 4px;
  border: 0;
}

</style>
