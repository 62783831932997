<template>
  <div class="tiles-container">
    <div  v-for="(layer, layerIndex) in measurementPoint.layers"
          :key="generateKey(layer,'layer_row')"
          class="layer-and-jars-container">
      <div class="layer-tile tile" :class="layer.selectedByUser ? 'red-border' : ''">
        <p  class="tile-text">{{layer.from}} - {{layer.to}}</p>
        <div
            :class="['bg', 'tex' + layer.soilType.code]"
            class="layer-background-soil">
          <div
              v-for="(admixture, admixIndex) in layer.additives"
              :key="generateKey(layer,`layer_admixture_${admixIndex}`)"
              :class="getAdmixtureBackgroundClasses(admixture)"
              class="layer-background-additives"/>
        </div>
        <div class="tooltip-container layer-tooltip-container"
          :style="{
            top: calculateLayerTooltipPosition(layer, layerIndex, measurementPoint.layers.length)
            }">
          <h4 class="tooltip-header">{{layer.from}} - {{layer.to}}</h4>
          <div v-for="layerTooltip in getLayerTooltip(layer)" :key="layerTooltip.key">
            <div v-if="layerTooltip.isList">
              <p class="tooltip-soiltype-title">
                {{layerTooltip.labelText}}
              </p>
              <ul>
                <li
                  v-for="item in layerTooltip.descriptionList"
                  :key="item.key"
                  class="tooltip-soiltype-text">
                  {{item.description}}
                </li>
              </ul>
            </div>
            <div v-else class="tooltip-soiltype-row">
              <p class="tooltip-soiltype-title">{{layerTooltip.labelText}}</p>
              <p class="tooltip-soiltype-text"> {{layerTooltip.description}}</p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showJars" class="jar-row-container">
        <div v-for="(jar, jarIndex) in layer.jars"
          :key="generateKey(jar, `jar${jarIndex}_${layer.guid}`)"
          :class="[{ 'red-border': jar.selectedByUser }, { 'orange-border': !jar.selectedByUser && jar.hasBeenSuggested }, { 'jarSelectable': canSwitchJars }]"
          class="jar-tile tile"
          @click="switchJar(layerIndex, jarIndex, jar)">
          <p v-if="jar.barcode" class="tile-text barcode-text">{{jar.barcode}}</p>
          <p class="tile-text">{{jar.from}} - {{jar.to}}</p>
          <div class="tooltip-container jar-tooltip-container">
            <h4 v-if="jar.barcode" class="tooltip-header">{{jar.barcode}}</h4>
            <div v-for="jarTooltip in getJarTooltip(jar)" :key="jarTooltip.key" class="tooltip-soiltype-row">
              <p class="tooltip-soiltype-title">{{jarTooltip.labelText}}</p>
              <p class="tooltip-soiltype-text"> {{jarTooltip.description}} </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SuggestAnalysisSampleBoxes',
  props: {
    measurementPoint: {
      type: Object,
      required: true
    },
    sampleNumber: {
      type: Number,
      required: true
    },
    canSwitchJars: {
      type: Boolean,
      required: true
    },
    showJars: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    getLayerTooltip(layer) {
      const toolTip = [
        {
          isList: false,
          labelText: `${this.$t('SuggestedSamples.SoilType')}:`,
          description: layer.soilType.description,
          key: this.generateKey(layer, 'layer_tooltip_soiltype')
        },
        {
          isList: false,
          labelText: `${this.$t('SuggestedSamples.SoilTypeDepth')}:`,
          description: layer.soilDepthType.description,
          key: this.generateKey(layer, 'layer_tooltip_soildepthtype')
        }
      ]
      if (layer.additives.length > 0) {
        toolTip.push(
          {
            isList: true,
            labelText: `${layer.additives.length > 1 ? `${this.$t('SuggestedSamples.SoilTypeAndAdditives')}` : `${this.$t('SuggestedSamples.SoilTypeAndAdditive')}`}:`,
            descriptionList: layer.additives.map((additive, additiveIndex) => {
              return { ...additive, key: this.generateKey(layer, `layer_tooltip_additives_${additiveIndex}`) }
            }),
            key: this.generateKey(layer, `layer_tooltip_additives`)
          })
      }
      if (layer.specialCompounds.length > 0) {
        toolTip.push(
          {
            isList: true,
            labelText: `${layer.specialCompounds.length > 1 ? `${this.$t('SuggestedSamples.SpecialCompounds')}` : `${this.$t('SuggestedSamples.SpecialCompound')}`}:`,
            descriptionList: layer.specialCompounds.map((specialCompound, specialCompoundIndex) => {
              return { ...specialCompound, key: this.generateKey(layer, `layer_tooltip_specialCompunds_${specialCompoundIndex}`) }
            }),
            key: this.generateKey(layer, 'layer_tooltip_specialCompunds')
          })
      }
      if (layer.oilWaterReaction.description) {
        toolTip.push(
          {
            isList: false,
            labelText: `${this.$t('SuggestedSamples.OilWaterReaction')}:`,
            description: layer.oilWaterReaction.description,
            key: this.generateKey(layer, 'layer_tooltip_oilwater')
          })
      }
      if (layer.odour.description) {
        toolTip.push(
          { isList: false,
            labelText: `${this.$t('SuggestedSamples.Odour')}:`,
            description: layer.odour.description,
            key: this.generateKey(layer, 'layer_tooltip_odour')
          })
      }
      if (layer.pid > 0) {
        toolTip.push(
          {
            isList: false,
            labelText: `${this.$t('SuggestedSamples.PID')}:`,
            description: layer.pid,
            key: this.generateKey(layer, 'layer_tooltip_pid')
          })
      }
      if (layer.remark) {
        toolTip.push(
          {
            isList: false,
            labelText: `${this.$t('SuggestedSamples.Remark')}:`,
            description: layer.remark,
            key: this.generateKey(layer, 'layer_tooltip_remark')

          })
      }
      return toolTip
    },
    calculateLayerTooltipPosition(layer, layerIndex, layersCount) {
      let propertiesCount = 2
      propertiesCount += layer.additives.length
      propertiesCount += layer.specialCompounds.length
      if (layer.oilWaterReaction.description) {
        propertiesCount++
      }
      if (layer.odour.description) {
        propertiesCount++
      }
      if (layer.pid > 0) {
        propertiesCount++
      }
      const isLastElement = (layersCount - layerIndex) === 1
      const isSecondToLastElement = ((layersCount - layerIndex) === 2)
      if (!isLastElement && !isSecondToLastElement) {
        if (propertiesCount >= 7 && (layerIndex + 2) > layersCount) {
          return '-60%'
        }
        // most common situtation
        return '40%'
      }
      switch (propertiesCount) {
        case 2: {
          return '-100%'
        }
        case 3: {
          return isLastElement ? '-190%' : '-150%'
        }
        case 4: {
          return isLastElement ? '-190%' : '-180%'
        }
        case 5: {
          return isLastElement ? '-220%' : '-210%'
        }
        case 6: {
          return isLastElement ? '-300%' : '-250%'
        }
        default: {
          return isLastElement ? '-450%' : '-400%'
        }
      }
    },
    getJarTooltip(jar) {
      const tooltipItems = [
        {
          labelText: `${this.$t('SuggestedSamples.JarName')}:`,
          description: jar.name,
          key: this.generateKey(jar, 'jar-tooltip-name')
        },
        {
          labelText: `${this.$t('SuggestedSamples.Barcode')}:`,
          description: jar.barcode,
          key: this.generateKey(jar, 'jar-tooltip-barcode')
        }
      ]
      if (jar.type.description) {
        tooltipItems.push({
          labelText: `${this.$t('SuggestedSamples.JarType')}:`,
          description: jar.type.description,
          key: this.generateKey(jar, 'jar-tooltip-type')
        })
      }
      if (jar.remark) {
        tooltipItems.push({
          labelText: `${this.$t('SuggestedSamples.Remark')}:`,
          description: jar.remark,
          key: this.generateKey(jar, 'jar-tooltip-remark')
        }
        )
      }
      return tooltipItems
    },
    generateKey(item, type) {
      return `${type}_${this.measurementPoint.name}_${this.sampleNumber}_${item.guid}`
    },
    getAdmixtureBackgroundClasses(admixture) {
      return ['tex' + admixture.soilType.code || '0', 'med' + admixture.gradation.code || '0']
    },
    switchJar(layerIndex, jarIndex) {
      if (!this.canSwitchJars) {
        return
      }
      this.$emit('switchJar', {
        layerIndex,
        jarIndex
      })
    }
  },
  computed: {
    selectedLayerIndex() {
      return this.measurementPoint.layers.findIndex(layer => layer.selectedByUser)
    },
    selectedJarIndex() {
      if (this.selectedLayerIndex === -1) {
        return 0
      }
      return this.measurementPoint.layers[this.selectedLayerIndex].jars.findIndex(jar => jar.hasBeenSuggested)
    }
  }
}
</script>
<style scoped >
  .tile {
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 60px;
    margin: 1px;
    background-color: #793f10;
    white-space: pre;
  }
  .layer-tile  {
    width:110px;
  }
  .layer-tile .tile-text {
    background-color: black;
    max-height: 26px;
  }
  .jar-tile {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .jar-tile p {
    padding: 0px;
  }
  .barcode-text {
    width: 95%;
    overflow: hidden;
  }
  .tile-text {
    z-index: 1;
    color: white;
    padding: 5px;
    text-align: center;
    vertical-align: middle;
    margin: 0px;
    font-size: 14px;
    font-family: Roboto,sans-serif;
    cursor: default;
    pointer-events: none;
  }
  .red-border {
    border: solid 4px #D32323;
    padding: 0px !important;
  }
  .orange-border {
    border: solid 4px #F5B400;
    padding: 0px !important;
  }
  .tiles-container {
    padding: 0px;
    margin: 0px 0px 25px 0px;
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  .texA {
    background: url("../../assets/textures/peat.gif");
  }
  .texG {
    background: url("../../assets/textures/gravel.gif");
  }
  .texH,
  .texV {
    background: url("../../assets/textures/wood.gif");
  }
  .texK {
    background: url("../../assets/textures/clay.gif");
  }
  .texL {
    background: url("../../assets/textures/l.gif");
  }
  .texS {
    background: url("../../assets/textures/silt.gif");
  }
  .texJ {
    background: url("../../assets/textures/silt.gif");
  }
  .texW {
    background: url("../../assets/textures/water.gif");
  }
  .texZ {
    background: url("../../assets/textures/sand.gif");
  }
  .med0 {
    display: none;
  }
  .med1 {
    width: 10%;
  }
  .med2 {
    width: 17%;
  }
  .med3 {
    width: 24%;
  }
  .med4 {
    width: 32%;
  }
  .layer-background-soil {
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: flex-end;
  }
  .layer-background-additives {
    height: 100%;
  }
  .jar-row-container {
    width: 110px;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .layer-and-jars-container {
    display: flex;
    width: 100%
  }
  .tile .tooltip-container {
    visibility: hidden;
    background-color: rgb(235 235 235 / 92%);
    text-align: start;
    border-radius: 10px;
    padding:10px;
    position: absolute;
    z-index: 10;
    top: 40%;
    left: 100%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
    border: solid 1px black;
    overflow-y: auto;
    overflow-x: hidden;
    white-space:pre;
    display: flex;
    flex-direction: column;
    max-height: 450px;
    font-family: Roboto,sans-serif;
}
.layer-and-jars-container:last-child .tooltip-container {
  top: -100%;
}
.layer-and-jars-container:nth-last-child(2) .tooltip-container {
  top: -100%;
}

.tile:hover .tooltip-container {
  visibility: visible;
  opacity: 1;
}
.tooltip-soiltype-row {
  display: flex;
  margin: 0 0 1px 3px;
  align-items: baseline;
  padding: 0px;
}
.tooltip-soiltype-list-title {
  margin: 0px 0 2px 0;
  padding: 0;
}
.tooltip-soiltype-text {
  margin-left: 5px;
  font-size: 14px;
  margin: 0;
  padding:0;
  white-space:pre-wrap;
  font-weight: 400;
}
.tooltip-soiltype-title {
  font-weight: 100;
  margin: 0;
  padding:0;
  font-size: 11px;
}

.tooltip-soiltype-title ul {
  margin-bottom: 2px;
}
.tooltip-header {
  text-align: center;
  margin-bottom: 2px;
  margin-top: 0px;
}
.layer-tooltip-container {
  max-width: 280px;
  width: 200px;
}
.jar-tooltip-container {
  width: 165px;
}
.jarSelectable {
  cursor: pointer;
}
.jarSelectable:hover {
  background-color: #7a3e0edb;
}
.jarSelectable:hover .tooltip-container {
  left: 138%;
}

</style>
